import React, { useState } from 'react';
import Headroom from 'react-headroom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const Header = () => {
  const location = useLocation();
  const [isLoggedIn, setLoggedIn] = useState(!!localStorage.getItem('userid'));
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('userid');
    setLoggedIn(false);
  };
  const handleUserIconClick = () => {
    const userId = localStorage.getItem('userid');
    axios.get(`${BASE_URL}user-login/user-type/${userId}`)
      .then((response) => {
        const data = response.data;
        const userType = data.userType;
        if (userType === 'investor') {
          navigate('/investor');
        } else if (userType === 'borrower') {
          navigate('/borrower');
        } else {
          console.error('Unknown user type:', userType);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  return (
    <Headroom style={{ zIndex: 99 }}>
      <div className='header-menu header-menu-4 navbar_fixed'>
        <Navbar expand="lg" className='bg_white' data-bs-theme="light">
          <Container>
            <Navbar.Brand as={Link} to="/" className='sticky_logo'>
              <img src={imgpath.ofinLogo} height="75" className="d-inline-block align-top" alt="Alumni logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav right-nav">
              <Nav className="mx-auto menu "  >
                <Nav.Item className='submenu'>
                  <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'active' : ''} > Home</Nav.Link>
                </Nav.Item>
                <Nav.Item className='submenu'>
                  <Nav.Link as={Link} to="/about" className={location.pathname === '/about' ? 'active' : ''} > About</Nav.Link>
                </Nav.Item>
                <NavDropdown title="Products" id="basic-nav-dropdown" className='submenu' active={location.pathname.includes('/products')} >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='arrow_carrot-down_alt2 mobile_dropdown_icon' aria-hidden="false" data-bs-toggle="dropdown" />
                  <NavDropdown.Item as={Link} to="/products" className="nav-item nav-link" > ProSecure </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products" className="nav-item nav-link" > EliteFund </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products" className="nav-item nav-link" > TrustCapital </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products" className="nav-item nav-link" > OptiFunds </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products" className="nav-item nav-link" > ExcelFunds </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products" className="nav-item nav-link" > UnityFunds </NavDropdown.Item>
                </NavDropdown>
                <Nav.Item className='submenu'>
                  <Nav.Link as={Link} to="/news" className={location.pathname === '/news' ? 'active' : ''} > News</Nav.Link>
                </Nav.Item>
                <Nav.Item className='submenu'>
                  <Nav.Link as={Link} to="/contact" className={location.pathname === '/contact' ? 'active' : ''} > Contact</Nav.Link>
                </Nav.Item>
              </Nav>
              {isLoggedIn ? (
                // If logged in, show logout button and dashboard
                <>
                  <Link className=" theme-btn home-btn theme-btn-outlined ps-3 pe-3" onClick={handleUserIconClick} >
                    <FontAwesomeIcon icon="fa-solid fa-user" size="lg" />
                  </Link>
                  <Link to='/login' className=" theme-btn" onClick={handleLogout}> Logout </Link>
                </>
              ) : (
                // If not logged in, show login and signup buttons
                <>
                  <Link to='/login' className=" theme-btn" > Login </Link>
                  <Link to='/signup' className="  theme-btn theme-btn-outlined " > SignUp </Link>
                </>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </Headroom>
  );
}
export default Header