import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const NewsDetail = () => {
  const [newsDetails, setNewsDetails] = useState({});
  const { slug } = useParams();
  useEffect(() => {
    console.log("Fetching news details for ID:", slug);
    axios.get(`${BASE_URL}news/${slug}`)
      .then((response) => {
        console.log('API response:', response);
        if (response?.data?.result === true) {
          setNewsDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching news details:', error);
      });
  }, [slug]);
  return (
    <>
      {/* Navigation */}
      <header className="App-header">
        <Header />
      </header>
      <main>
        {/* Breadcrumb area */}
        <section className="breadcrumb-area">
          <div className="breadcrumb-widget pt-145 pb-125">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="breadcrumb-content">
                    <h1> News Detail </h1>
                    <ul>
                      <li> <a href="/">Home</a> </li>
                      <li> News Detail </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* News Details Section */}
        <section className='pt-120 pb-100 bg_disable'>
          <Container>
            <Row className='justify-content-center'>
              <Col lg={10} sm={12}>
                <div className='post-details-widget position-relative'>
                  <img className='post-img w-100' src={BASE_URL + `news/uploads/${newsDetails.profile}`} alt='post img' />
                  <div dangerouslySetInnerHTML={{ __html: newsDetails.detailed_desc }} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      {/* Footer Section */}
      <footer className="App-footer">
        <Footer />
      </footer>
    </>
  )
}
export default NewsDetail