export const imgpath={ 
    ofinLogo: `${process.env.PUBLIC_URL}/assets/img/logo/ofin-logo.png`,
    footerLogo: `${process.env.PUBLIC_URL}/assets/img/logo/white-logo.png`,
    // Banner Content Home
    bannerImg: `${process.env.PUBLIC_URL}/assets/img/home/banner.png`,
    shape12: `${process.env.PUBLIC_URL}/assets/img/shapes/shape-12.png`,
    shape8: `${process.env.PUBLIC_URL}/assets/img/shapes/shape-8.png`,
    shape7: `${process.env.PUBLIC_URL}/assets/img/shapes/shape-7.png`,
    shape6: `${process.env.PUBLIC_URL}/assets/img/shapes/shape-6.png`,
    shape10: `${process.env.PUBLIC_URL}/assets/img/shapes/shape-10.png`,
    shape9: `${process.env.PUBLIC_URL}/assets/img/shapes/shape-9.png`,
    shape11: `${process.env.PUBLIC_URL}/assets/img/shapes/shape-11.png`,
    // Faq section Home Page
    faqImg: `${process.env.PUBLIC_URL}/assets/img/faq/1.png`,
    // About Page
    about_bg: `${process.env.PUBLIC_URL}/assets/img/about/abt-bg.png`,
    about_bg2: `${process.env.PUBLIC_URL}/assets/img/bg/bg-img-2.png`,
    // Contact Page
    callImg:`${process.env.PUBLIC_URL}/assets/img/contact/call-outline.png`,
    mailImg:`${process.env.PUBLIC_URL}/assets/img/contact/mail-open-outline.png`,
    // Products Page
    slide1:`${process.env.PUBLIC_URL}/assets/img/shapes/slide-shape-1.png`,
    girlImg:`${process.env.PUBLIC_URL}/assets/img/bg/p2.png`,
    icon1:`${process.env.PUBLIC_URL}/assets/img/icons/icon-1.svg`,
    icon2:`${process.env.PUBLIC_URL}/assets/img/icons/icon-2.svg`,
    icon3:`${process.env.PUBLIC_URL}/assets/img/icons/icon-3.svg`,
    icon4:`${process.env.PUBLIC_URL}/assets/img/icons/icon-4.svg`,
    icon11:`${process.env.PUBLIC_URL}/assets/img/icons/icon-11.svg`,
    icon12:`${process.env.PUBLIC_URL}/assets/img/icons/icon-12.svg`,
    icon13:`${process.env.PUBLIC_URL}/assets/img/icons/icon-13.svg`,
    icon14:`${process.env.PUBLIC_URL}/assets/img/icons/icon-14.svg`,
    icon15:`${process.env.PUBLIC_URL}/assets/img/icons/icon-15.svg`,
    icon16:`${process.env.PUBLIC_URL}/assets/img/icons/icon-16.svg`,
    video_ofin:`${process.env.PUBLIC_URL}/assets/videos/ofin.mp4`,
    // investor logos
    investor1: `${process.env.PUBLIC_URL}/assets/img/investors/c_logo_1.png`,
    investor2: `${process.env.PUBLIC_URL}/assets/img/investors/c_logo_2.png`,
    investor3: `${process.env.PUBLIC_URL}/assets/img/investors/c_logo_3.png`,
    investor4: `${process.env.PUBLIC_URL}/assets/img/investors/c_logo_1.png`,
    investor5: `${process.env.PUBLIC_URL}/assets/img/investors/c_logo_5.png`,
    // customer Testimonials
    customer1: `${process.env.PUBLIC_URL}/assets/img/faq/img-1.png`,
    customer2: `${process.env.PUBLIC_URL}/assets/img/faq/img-2.png`,
    // investor Page
    total_req: `${process.env.PUBLIC_URL}/assets/img/icons/total-request.png`,
    approved_req: `${process.env.PUBLIC_URL}/assets/img/icons/approve.png`,
    // Borrower Page
    completed_req: `${process.env.PUBLIC_URL}/assets/img/icons/completed-request.png`,
    pending_req: `${process.env.PUBLIC_URL}/assets/img/icons/pending-request.png`,
    deleteIcon: `${process.env.PUBLIC_URL}/assets/img/icons/trash.png`,
    // subscribe content
    contactImg: `${process.env.PUBLIC_URL}/assets/img/contact/contact.png`,
    ctaImg: `${process.env.PUBLIC_URL}/assets/img/shapes/cta-shape.png`,
}

   
