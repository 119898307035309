import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Keyboard, Scrollbar, Navigation } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const Testimonials = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        axios.get(BASE_URL + `testimonial`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setData(response.data.data)
                }
            })
    }, []);
    return (
        <section className='client-area-2'>
            <Container >
                <div className='section-title'>
                    <span className='short-title-2'>_Testimonials</span>
                    <h1> Customer <span className='underline-shape'>feedbacks</span></h1>
                    <p className='mt-3 mb-0'>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration in some form,</p>
                </div>
                <Row>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={3}
                        slidesPerGroupSkip={0}
                        grabCursor={false}
                        keyboard={{
                            enabled: true,
                        }}
                        breakpoints={{
                            992: { slidesPerView: 3, slidesPerGroup: 3, },
                            991: { slidesPerView: 2, slidesPerGroup: 2, },
                            768: { slidesPerView: 2, slidesPerGroup: 2, },
                            767: { slidesPerView: 1, slidesPerGroup: 1, },
                            350: { slidesPerView: 1, slidesPerGroup: 1, },
                        }}
                        autoplay={{ delay: 2500, disableOnInteraction: false, }}
                        pagination={{ clickable: true, }}
                        // navigation={true}
                        modules={[Autoplay, Pagination, Navigation, Keyboard, Scrollbar]}
                        className="client-slider-2 mb-5 pb-5" >
                        {
                            data.map(info => {
                                return (
                                    <Col xl={4} key={info._id}>
                                        <SwiperSlide key={info._id} >
                                            <div className='single-client'>
                                                {info.rating == "5" &&
                                                    <div className='rating'>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                    </div>
                                                }
                                                {info.rating == "4" &&
                                                    <div className='rating'>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                    </div>
                                                }
                                                {info.rating == "3" &&
                                                    <div className='rating'>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                    </div>
                                                }
                                                {info.rating == "2" &&
                                                    <div className='rating'>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                    </div>
                                                }
                                                {info.rating == "1" &&
                                                    <div className='rating'>
                                                        <a href='/' tabIndex={-1}> <FontAwesomeIcon icon="fas fa-star" /> </a>
                                                    </div>
                                                }
                                                <p className='quote'> {info.feedback} </p>
                                                <div className='client-info'>
                                                    <img src={BASE_URL + `testimonial/uploads/${info.profile}`} alt='client' />
                                                    <div>
                                                        <p>{info.userName}</p>
                                                        <span className='role'> {info.desigination}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Col>
                                )
                            })
                        }
                    </Swiper>
                </Row>
            </Container>
        </section>
    )
}
export default Testimonials