import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const AboutTab = () => {
  const [data, setData] = useState('')
  useEffect(() => {
    axios.get(`${BASE_URL}tabsforHome`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data)
        }
      })
  }, [])
  return (
    <section className="about-tab-area bg_white">
      <Container>
        <Tabs >
          <TabList className="nav nav-tabs" >
            <Tab> {data.tabOneHeader} </Tab>
            <Tab> {data.tabTwoHeader} </Tab>
            <Tab> {data.tabThreeHeader} </Tab>
            <Tab> {data.tabFourHeader} </Tab>
          </TabList >
          <div className="tab-content" >
            <TabPanel>
              <Row className='align-items-center'>
                <Col lg={5} sm={12}>
                  <div className='section-title text-start'>
                    {/* <h1> Access to secure <span className='underline-shape'> banking</span></h1> */}
                    <h1> {data.tabOneTitle} </h1>
                    <p> {data.tabOneDesc} </p>
                    {/* <p> Lorem Ipsum is simply dummy text of the printing and the typesetting industry. Lorem Ipsum has been industry's </p> */}
                    <Link to={data.tabOneBtnPath} className='read-more'> {data.tabOneBtnText} <FontAwesomeIcon icon="fas fa-arrow-right" /> </Link>
                  </div>
                </Col>
                <Col lg={7} sm={12}>
                  <img className='img-fluid' src={`${BASE_URL}tabsforHome/uploads/${data.tabOneImage}`} alt='ofin' />
                </Col>
              </Row>
            </TabPanel>
            <TabPanel>
              <Row className='align-items-center'>
                <Col lg={5} sm={12}>
                  <div className='section-title text-start'>
                    <h1> {data.tabTwoTitle} </h1>
                    <p> {data.tabTwoDesc} </p>
                    <Link to={data.tabTwoBtnPath} className='read-more'> {data.tabTwoBtnText} <FontAwesomeIcon icon="fas fa-arrow-right" /> </Link>
                  </div>
                </Col>
                <Col lg={7} sm={12}>
                  <img className='img-fluid' src={`${BASE_URL}tabsforHome/uploads/${data.tabTwoImage}`} alt='ofin' />
                </Col>
              </Row>
            </TabPanel>
            <TabPanel>
              <Row className='align-items-center'>
                <Col lg={5} sm={12}>
                  <div className='section-title text-start'>
                    <h1> {data.tabThreeTitle} </h1>
                    <p> {data.tabThreeDesc} </p>
                    <Link to={data.tabThreeBtnPath} className='read-more'> {data.tabThreeBtnText} <FontAwesomeIcon icon="fas fa-arrow-right" /> </Link>
                  </div>
                </Col>
                <Col lg={7} sm={12}>
                  <img className='img-fluid' src={`${BASE_URL}tabsforHome/uploads/${data.tabThreeImage}`} alt='ofin' />
                </Col>
              </Row>
            </TabPanel>
            <TabPanel>
              <Row className='align-items-center'>
                <Col lg={5} sm={12}>
                  <div className='section-title text-start'>
                    <h1> {data.tabFourTitle} </h1>
                    <p> {data.tabFourDesc} </p>
                    <Link to={data.tabFourBtnPath} className='read-more'> {data.tabFourBtnText} <FontAwesomeIcon icon="fas fa-arrow-right" /> </Link>
                  </div>
                </Col>
                <Col lg={7} sm={12}>
                  <img className='img-fluid' src={`${BASE_URL}tabsforHome/uploads/${data.tabFourImage}`} alt='ofin' />
                </Col>
              </Row>
            </TabPanel>
          </div>
        </Tabs>
      </Container>
    </section>
  );
}
export default AboutTab