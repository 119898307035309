import React, { useState, useEffect } from 'react'
import Header from './Header';
import Footer from './Footer';
import Banner from '../components/Banner';
import Blog from '../homePage/Blog';
import Testimonials from '../homePage/Testimonials';
import Feature from '../homePage/Feature';
import Faq from '../homePage/Faq';
import AboutTab from '../homePage/AboutTab';
import ServiceCompare from '../homePage/ServiceCompare';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const Home = () => {
    const [data, setData] = useState([]);
    const slug = 'home';
    useEffect(() => {
        axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setData(response.data.data);
                }
            })
    }, []);
    return (
        <>
            <Helmet>
                {data.length > 0 && <title>{String(data[0].metaTitle)}</title>}
                {data.length > 0 && <meta name="description" content={String(data[0].meta_desc)} />}
                {data.length > 0 && <meta name="keywords" content={String(data[0].keywords)} />}
            </Helmet>
            {/* Navigation Section */}
            <header className="App-header"> <Header /> </header>
            <main>
                <Banner />
                {/* Features Section */}
                <Feature />
                {/* Tabs Section Home Page */}
                <AboutTab />
                {/* Faq Section */}
                <Faq />
                {/* Service Comparison Section */}
                <ServiceCompare />
                {/* Client Testimonial Section*/}
                <Testimonials />
                {/* News Section for HomePage */}
                <Blog />
            </main>
            {/* Footer Section */}
            <footer className="App-footer"> <Footer /> </footer>
        </>
    );
}
export default Home