import React, { useState, useEffect, useMemo } from 'react'
import InvestorHeader from './InvestorHeader';
import { CopyRights } from '../pages/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const ClosedLoans = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [helmetData, setHelmetData] = useState([]);
    const slug = 'investor-closedloans';
    useEffect(() => {
        axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setHelmetData(response.data.data);
                }
            })
    }, []);
    const getData = async () => {
        const invID = localStorage.getItem("userid");
        await axios.get(`${BASE_URL}requests?invID=${invID}&status=active`)
            .then(async (res) => {
                const requestData = res.data.data;
                const paymentDataPromises = requestData.map(async (request) => {
                    const response = await axios.get(`${BASE_URL}payment?invId=${invID}&reqId=${request._id}`);
                    return response.data.data;
                });
                const paymentDataList = await Promise.all(paymentDataPromises);
                // Merge payment data into request data
                const mergedData = requestData.map((request, index) => ({
                    ...request,
                    paidAmount: paymentDataList[index].reduce((total, payment) => total + payment.paidAmount, 0),
                }));
                setData(mergedData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }
    useEffect(() => {
        getData()
    }, [])
    const handlePayDue = (requestId) => {
        navigate(`/investor-payment-details/${requestId}`);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(Math.ceil(data.length / perPage));
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [sortedColumn, setSortedColumn] = useState(null);
    const sortedData = useMemo(() => {
        const sortableData = [...data];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'descending' ? -1 : 1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);
    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        setSortConfig({ key, direction });
    };
    const upArrowColor = sortConfig.direction === 'ascending' ? 'sorting_asc' : 'sorting';
    const downArrowColor = sortConfig.direction === 'descending' ? 'sorting_desc' : 'sorting';
    const filteredData = useMemo(() => {
        return sortedData.filter(item => {
            const values = Object.values(item).map(val => val.toString().toLowerCase());
            return (
                values.some(val => val.includes(searchQuery.toLowerCase())) &&
                item.amountRequired === item.paidAmount
            );
        });
    }, [sortedData, searchQuery]);
    const displayData = useMemo(() => {
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        return filteredData.slice(startIndex, endIndex);
    }, [currentPage, perPage, filteredData]);
    useEffect(() => {
        setTotalPages(Math.ceil(filteredData.length / perPage));
    }, [filteredData, perPage]);
    const Size = [10, 25, 50, 100];
    const handlePerPageChange = (event) => {
        const newPerPage = parseInt(event.target.value, 10);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const generatePageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : 'disabled'}`}>
                    <button className="page-link" onClick={() => handlePageClick(i)}> {i} </button>
                </li>
            );
        }
        return pageItems;
    };
    const generateTableHead = () => {
        const columns = [
            { key: 'index', label: 'S.No' },
            { key: 'borrowerName', label: 'Borrower Name' },
            { key: 'productName', label: 'Product Name' },
            { key: 'amountRequired', label: 'Amount (₹)' },
            { key: 'paidAmount', label: 'Paid Amount (₹)' },
            { key: 'balanceAmount', label: 'Balance Amount (₹)' },
            // { key: 'start_date', label: 'Start Date' },
            // { key: 'end_date', label: 'End Date' },
            { key: '', label: 'Status' },
            { key: '', label: 'Payment' },
        ];
        return (
            <thead>
                <tr>
                    {columns.map((column) => (
                        <th key={column._key} className={`${upArrowColor} ${downArrowColor}`} onClick={() => handleSort(column.key)}>
                            {column.label}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    };
    useEffect(() => {
        setSortedColumn(sortConfig.key);
    }, [sortConfig.key]);
    return (
        <>
            <Helmet>
                {helmetData.length > 0 && <title>{String(helmetData[0].metaTitle)}</title>}
                {helmetData.length > 0 && <meta name="description" content={String(helmetData[0].meta_desc)} />}
                {helmetData.length > 0 && <meta name="keywords" content={String(helmetData[0].keywords)} />}
            </Helmet>
            <header>
                <InvestorHeader />
            </header>
            <main>
                <section className='loan-deatils-area bg_disable pt-100 pb-100'>
                    <Container>
                        <Row className=' wow fadeInUp'>
                            <Col lg={12}>
                                <div className='info-box'>
                                    <div id='table_wrapper' className='dataTables_wrapper dt-bootstrap5 no-footer'>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} >
                                                <div className='dataTables_length' id='table_length'>
                                                    <label className='d-flex'> Show   <select name="table_length" aria-controls="table"
                                                        className="form-select form-select-sm w-auto ms-2 me-2"
                                                        onChange={handlePerPageChange}
                                                        value={perPage} > {Size.map((option) => (<option key={option} value={option}> {option}  </option>))} </select> entries</label>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} >
                                                <div id="table_filter" className="dataTables_filter ">
                                                    <label className='d-flex justify-content-end' > Search: <input type="text" className="form-control w-auto ms-2 form-control-sm"
                                                        aria-controls="table"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange} />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <table id="table" className='table table-responsive table-bordered example  table-borderless table-thead-bordered nowrap table-align-middle card-table dataTable no-footer '>
                                                    {generateTableHead()}
                                                    {
                                                        displayData.map((data, index) => {
                                                            return (
                                                                <tbody key={data._id}>
                                                                    <tr className='odd' >
                                                                        <td> {((currentPage - 1) * perPage) + index + 1} </td>
                                                                        <td> {data.borrowerName} </td>
                                                                        <td> {data.productName} </td>
                                                                        <td> {data.amountRequired} </td>
                                                                        <td> {data.paidAmount} </td>
                                                                        <td>{data.amountRequired - data.paidAmount}</td>
                                                                        {/* <td> {data.start_date} </td> */}
                                                                        {/* <td> {data.end_date} </td> */}
                                                                        <td> Closed </td>
                                                                        <td>
                                                                            <button className="pay-button" onClick={() => handlePayDue(data._id)} > View </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        })}
                                                    {displayData && !displayData.length &&
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="10" className="text-center">
                                                                    <div className="p-2">No matching records found</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }
                                                </table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={5}>
                                                <div className="dataTables_info" id="table_info" aria-live="polite"> Showing {((currentPage - 1) * perPage) + 1} to {Math.min(currentPage * perPage, filteredData.length)} of {filteredData.length} entries </div>
                                            </Col>
                                            <Col sm={12} md={7}>
                                                <div className="dataTables_paginate paging_simple_numbers " id="table_paginate">
                                                    <ul className="pagination d-flex justify-content-end">
                                                        <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`} id="table_previous" >
                                                            <button className="page-link" onClick={handlePreviousPage} disabled={currentPage === 1}>
                                                                Previous
                                                            </button>
                                                        </li>
                                                        {generatePageItems()}
                                                        <li className={`paginate_button page-item next ${currentPage === totalPages ? 'disabled' : ''}`} id="table_next">
                                                            <button className="page-link" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                                                Next
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <footer className=' footer footer-3 fixed-footer '>
                <CopyRights />
            </footer>
        </>
    )
}
export default ClosedLoans