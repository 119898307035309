import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const ServiceCompare = () => {
    const { ref, inView } = useInView({
        triggerOnce: false,
    });
    const [data, setData] = useState([])
    const getData = async () => {
        try {
            const res = await axios.get(`${BASE_URL}countsforHome`);
            setData(res.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        getData()
    }, [])
    return (
        <section className='services-area'>
            <Container>
                <div className='section-title mb-45'>
                    <span className='short-title-2'> _SERVICE COMPARISONS </span>
                    <h1> Our better <span className='underline-shape'>services</span>  </h1>
                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration in some form,</p>
                </div>
                <div className='table-responsive'>
                    <table className='table table-borderless mb-0'>
                        <thead>
                            <tr>
                                <th className='feature-head' scope='col'></th>
                                <th className='text-center' scope='col'> <img src={imgpath.ofinLogo} height="75" alt='logo' /> </th>
                                <th className='text-center' scope='col'> Other Companies </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className='feature-head' scope='row'> Payment Per Transaction </th>
                                <td className='feature-item'>0.50/per transacrion</td>
                                <td className='feature-item'>1.50/per transacrion</td>
                            </tr>
                            <tr>
                                <th className='feature-head' scope='row'> Bank account limitations </th>
                                <td className='feature-item'> up to 10 bank accounts </td>
                                <td className='feature-item'> Only 3 bank accounts </td>
                            </tr>
                            <tr>
                                <th className='feature-head' scope='row'> Local fees capped at NGN 2,000 </th>
                                <td className='feature-item'> <FontAwesomeIcon icon="fas fa-check-circle" /> </td>
                                <td className='feature-item'> <FontAwesomeIcon icon="fas fa-times-circle" />  </td>
                            </tr>
                            <tr>
                                <th className='feature-head' scope='row'> Loan Purchase </th>
                                <td className='feature-item'> easy to follow steps </td>
                                <td className='feature-item'> complicated steps </td>
                            </tr>
                            <tr>
                                <th className='feature-head' scope='row'> Debt Financing </th>
                                <td className='feature-item'> <FontAwesomeIcon icon="fas fa-check-circle" /> </td>
                                <td className='feature-item'> <FontAwesomeIcon icon="fas fa-times-circle" />  </td>
                            </tr>
                            <tr>
                                <th className='feature-head' scope='row'> No hidden fees or charges </th>
                                <td className='feature-item'> <FontAwesomeIcon icon="fas fa-check-circle" /> </td>
                                <td className='feature-item'> <FontAwesomeIcon icon="fas fa-times-circle" />  </td>
                            </tr>
                            <tr>
                                <th className='feature-head' scope='row'> Local fees capped </th>
                                <td className='feature-item'> <FontAwesomeIcon icon="fas fa-check-circle" /> </td>
                                <td className='feature-item'> <FontAwesomeIcon icon="fas fa-times-circle" />  </td>
                            </tr>
                            <tr>
                                <th className='feature-head bg-transparent' scope='row'></th>
                                <td className='feature-item bg-transparent p-0'>
                                    <Link to='/products' className='theme-btn'> <span>Get Started Now </span>  <FontAwesomeIcon icon="fas fa-arrow-right" />  </Link>
                                </td>
                                <td className='feature-item bg-transparent'></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* Count Section */}
                <div className='pt-60 stat-view'>
                    <Row className='gy-md-0 gy-4'>
                        <Col md={4} sm={12} ref={ref} className='border-end'  >
                            <h1 className='counter'> <CountUp scrollSpyOnce start={inView ? 0 : 10} end={inView ? data?.countoneNumber || 0 : 0} duration={1} /> {data.countoneText} </h1>
                            <p>  {data.countoneTitle} </p>
                        </Col>
                        <Col md={4} sm={12} ref={ref} >
                            <h1 className='counter'> <CountUp scrollSpyOnce start={inView ? 0 : 10} end={inView ? data?.counttwoNumber || 0 : 0} duration={1} /> {data.counttwoText} </h1>
                            <p>  {data.counttwoTitle} </p>
                        </Col>
                        <Col md={4} sm={12} ref={ref} className='border-start'>
                            <h1 className='counter'> <CountUp scrollSpyOnce start={inView ? 0 : 10} end={inView ? data?.countthreeNumber || 0 : 0} decimals={2} duration={1} /> {data.countthreeText} </h1>
                            <p>  {data.countthreeTitle} </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export default ServiceCompare