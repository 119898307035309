import React, { useState, useEffect } from 'react'
import Header from './Header';
import Footer from './Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Col, Container, Row, } from "react-bootstrap";
import InvestorSignUp from "../components/InvestorSignUp";
import BorrowerSignUp from "../components/BorrowerSignUp";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const Signup = () => {
  const [data, setData] = useState([]);
  const slug = 'signup';
  useEffect(() => {
    axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      })
  }, []);
  return (
    <>
      <Helmet>
        {data.length > 0 && <title>{String(data[0].metaTitle)}</title>}
        {data.length > 0 && <meta name="description" content={String(data[0].meta_desc)} />}
        {data.length > 0 && <meta name="keywords" content={String(data[0].keywords)} />}
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <section className="breadcrumb-area">
          <div className="breadcrumb-widget pt-145 pb-125">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="breadcrumb-content">
                    <h1> Sign Up </h1>
                    <ul>
                      <li> <a href="/"> Investor </a> </li>
                      <li> Sign Up </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* Sign Up Section */}
        <section className="loan-details-area bg_disable pt-100 pb-100">
          <Container>
            <Row className="justify-content-center">
              <Col lg={7}>
                <div className='loan-details-widget signup-form'>
                  <Tabs>
                    <TabList className='d-flex text-center'>
                      <Tab > Sign up as an Investor </Tab>
                      <Tab >  Sign up as a Borrower </Tab>
                    </TabList>
                    <div className="tab-content">
                      <TabPanel>
                        <InvestorSignUp />
                      </TabPanel>
                      <TabPanel>
                        <BorrowerSignUp />
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      {/* Footer Section */}
      <footer className="App-footer">
        <Footer />
      </footer>
    </>
  );
}
export default Signup