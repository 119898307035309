import './App.css';
import './default.css';
import './Responsive.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import News from './pages/News';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';
import NewsDetail from './components/NewsDetail';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvDashboard from './investor/InvDashboard';
import InvReqq from './investor/InvReqq';
import InvAppReq from './investor/InvAppReq';
import BorrowerHome from './borrower/BorrowerHome';
import AddRequest from './borrower/AddRequest';
import RequestStatus from './borrower/RequestStatus';
import ApprovedRequest from './borrower/ApprovedRequest'
import PaymentDetails from './investor/PaymentDetails';
import Payments from './borrower/Payments';
import Paydue from './borrower/Paydue';
import ClosedRequests from './borrower/ClosedRequests';
import ClosedLoans from './investor/ClosedLoans';
import { HelmetProvider } from 'react-helmet-async';
function App() {
  library.add(fab, fas, far)
  return (
    <div className="App">
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/products' element={<Products />} />
            <Route path='/news' element={<News />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/newsdetails/:slug' element={<NewsDetail />} />
            {/* Investor Dashboard */}
            <Route path='/investor' element={<InvDashboard />} />
            <Route path='/investor-requests' element={<InvReqq />} />
            <Route path='/investor-approved-request' element={<InvAppReq />} />
            <Route path='/investor-payment-details/:id' element={<PaymentDetails />} />
            <Route path='/investor-closedloans' element={<ClosedLoans />} />
            {/* Borrower Dashboard */}
            <Route path='/borrower' element={<BorrowerHome />} />
            <Route path='/borrower-add-request' element={<AddRequest />} />
            <Route path='/borrower-request-status' element={<RequestStatus />} />
            <Route path='/borrower-approved-request' element={<ApprovedRequest />} />
            <Route path='/borrower-payments/:id' element={<Payments />} />
            <Route path='/borrower-paynow/:id' element={<Paydue />} />
            <Route path='/borrower-closed-requests' element={<ClosedRequests />} />
          </Routes>
        </BrowserRouter>
        <ScrollToTop smooth component={<FontAwesomeIcon icon="fa-solid fa-chevron-up" />} />
      </HelmetProvider>
    </div>
  );
}

export default App;
