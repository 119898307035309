import React, { useState, useEffect } from 'react';
import InvestorHeader from './InvestorHeader';
import { CopyRights } from '../pages/Footer';
import { useInView } from "react-intersection-observer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountUp from "react-countup";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const InvDashboard = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });
  const [totalRequests, setTotalRequests] = useState(0);
  const [approvedRequests, setApprovedRequests] = useState(0);
  const [helmetData, setHelmetData] = useState([]);
  const slug = 'investor';
  useEffect(() => {
    axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
      .then((response) => {
        if (response?.data?.result === true) {
          setHelmetData(response.data.data);
        }
      })
  }, []);
  useEffect(() => {
    axios.get(BASE_URL + "requests")
      .then((response) => {
        const allRequestsData = response.data.data;
        // Set the total requests count
        setTotalRequests(allRequestsData.length);
      })
      .catch((error) => {
        console.error("Error fetching all requests data:", error);
      });
    // Fetch approved requests for the specific investor
    const invID = localStorage.getItem("userid");
    axios.get(`${BASE_URL}requests?invID=${invID}`)
      .then((response) => {
        const approvedRequestsData = response.data.data;
        // Filter and set the count of approved requests
        const approvedRequestsCount = approvedRequestsData.filter(request => request.status === "active").length;
        setApprovedRequests(approvedRequestsCount);
      })
      .catch((error) => {
        console.error("Error fetching active requests data:", error);
      });
  }, []);
  return (
    <>
      <Helmet>
        {helmetData.length > 0 && <title>{String(helmetData[0].metaTitle)}</title>}
        {helmetData.length > 0 && <meta name="description" content={String(helmetData[0].meta_desc)} />}
        {helmetData.length > 0 && <meta name="keywords" content={String(helmetData[0].keywords)} />}
      </Helmet>
      <header>
        <InvestorHeader />
      </header>
      <main>
        <section className="safe-deposit-area overflow-hidden pt-125 pb-115 bg_disable request-counter-section">
          <Container>
            <Row className='justify-content-center'>
              <Col lg={3} sm={6} ref={ref}>
                <div className='counter wow fadeInUp'>
                  <div className='counter-content'>
                    <div className='counter-icon'>
                      <img src={imgpath.total_req} alt='/' className='img-fluid' />
                    </div>
                    <h3>Total Request</h3>
                    <span className="counter-value"> <CountUp scrollSpyOnce start={inView ? 0 : 0} end={inView ? totalRequests : totalRequests} duration={2} /> </span>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={6} ref={ref}>
                <div className='counter wow fadeInUp'>
                  <div className='counter-content'>
                    <div className='counter-icon'>
                      <img src={imgpath.approved_req} alt='/' className='img-fluid' />
                    </div>
                    <h3>APPROVED REQUEST</h3>
                    <span className="counter-value"> <CountUp scrollSpyOnce start={inView ? 0 : 0} end={inView ? approvedRequests : approvedRequests} duration={2} /> </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <footer className=' footer footer-3 fixed-footer '>
        <CopyRights />
      </footer>
    </>
  )
}
export default InvDashboard