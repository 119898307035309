import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Subscribe from '../components/Subscribe';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const News = () => {
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [helmetData, setHelmetData] = useState([])
  const itemsPerPage = 6;
  const pagesVisited = pageNumber * itemsPerPage;
  useEffect(() => {
    axios.get(BASE_URL + `news`).then((response) => {
      if (response?.data?.result === true) {
        setData(response.data.data);
      }
    });
  }, []);
  const slug = 'news';
  useEffect(() => {
    axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
      .then((response) => {
        if (response?.data?.result === true) {
          setHelmetData(response.data.data);
        }
      })
  }, []);
  const displayData = data.slice(pagesVisited, pagesVisited + itemsPerPage).map((blogs) => {
    return (
      <Col lg={4} md={6} sm={12} key={blogs._id}>
        <div className='blog-widget-1 wow fadeInUp'>
          <img
            className='w-sm-auto w-100'
            src={BASE_URL + `news/uploads/${blogs.profile}`}
            alt='blog'
            srcSet={BASE_URL + `news/uploads/${blogs.profile} 2x`}
          />
          <div className='blog-content pr-20 pl-20'>
            <h4>
              <Link to={`/newsdetails/${blogs.slug}`} > {blogs.title} </Link>
            </h4>
            <p className='mb-0'> {blogs.short_desc} </p>
            <Link to={`/newsdetails/${blogs.slug}`} className='read-more'>
              Read More <FontAwesomeIcon icon='fas fa-arrow-right' className='fa-xs' />
            </Link>
          </div>
        </div>
      </Col>
    );
  });
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      <Helmet>
        {helmetData.length > 0 && <title>{String(helmetData[0].metaTitle)}</title>}
        {helmetData.length > 0 && <meta name="description" content={String(helmetData[0].meta_desc)} />}
        {helmetData.length > 0 && <meta name="keywords" content={String(helmetData[0].keywords)} />}
      </Helmet>
      {/* Navigation */}
      <header className="App-header">
        <Header />
      </header>
      <main>
        {/* Breadcrumb area */}
        <section className="breadcrumb-area">
          <div className="breadcrumb-widget pt-145 pb-125">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="breadcrumb-content">
                    <h1> News </h1>
                    <ul>
                      <li> <a href="/">Home</a> </li>
                      <li> News </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section className='news-area pt-100 pb-100'>
          <Container>
            <Row className='gy-4'>
              {displayData}
            </Row>
            <Row className='mb-50 mt-5'>
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={'pagination_news'}
                previousLinkClassName={'pagination__link'}
                nextLinkClassName={'pagination__link'}
                disabledClassName={'pagination__link--disabled'}
                activeClassName={'pagination__link--active'} />
            </Row>
          </Container>
        </section>
      </main>
      {/* Footer Section */}
      <footer className="App-footer">
        <Footer />
      </footer>
    </>
  );
}
export default News