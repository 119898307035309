import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const Feature = () => {
    const [data, setData] = useState('')
    const [ofinData, setOfinData] = useState('')
    useEffect(() => {
        axios.get(`${BASE_URL}processforHome`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setData(response.data.data)
                }
            })
    }, [])
    useEffect(() => {
        axios.get(`${BASE_URL}why-ofin`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setOfinData(response.data.data)
                }
            })
    }, [])
    return (
        <>
            <section className='steps-area bg_disable'>
                <Container>
                    <Row>
                        <Col lg={6} className='mx-auto steps-area-top-row'>
                            <div className='section-title'>
                                <span className='short-title-2'>_OUR PROCESS</span>
                                <h1 className='wow fadeInUp h1-title-back' > {data.processTitle} </h1>
                                <p className='wow fadeInUp' > {data.description} </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='gy-xl-0 gy-4'>
                        <Col xl={4} md={6} sm={12} >
                            <div className='feature-card-widget wow fadeInUp'>
                                <div className='card-img'>
                                    <img src={`${BASE_URL}processforHome/uploads/${data.steponeIcon}`} alt='feature-1' />
                                </div>
                                <h4> {data.steponeTitle} </h4>
                                <p> {data.steponeDesc} </p>
                            </div>
                        </Col>
                        <Col xl={4} md={6} sm={12} >
                            <div className='feature-card-widget wow fadeInUp'>
                                <div className='card-img'>
                                    <img src={`${BASE_URL}processforHome/uploads/${data.steptwoIcon}`} alt='feature-1' />
                                </div>
                                <h4> {data.steptwoTitle} </h4>
                                <p> {data.steptwoDesc} </p>
                            </div>
                        </Col>
                        <Col xl={4} md={6} sm={12} className='mx-auto' >
                            <div className='feature-card-widget wow fadeInUp'>
                                <div className='card-img'>
                                    <img src={`${BASE_URL}processforHome/uploads/${data.stepthreeIcon}`} alt='feature-1' />
                                </div>
                                <h4> {data.stepthreeTitle} </h4>
                                <p> {data.stepthreeDesc} </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* About Area */}
            <section className='about-area bg_white '>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div className='cash-img'>
                                <img className='img-fluid' src={`${BASE_URL}why-ofin/uploads/${ofinData.whyofinImg}`} alt='Ofin' />
                            </div>
                        </Col>
                        <Col lg={{ span: 5, offset: 1 }} className='wow fadeInLeft'>
                            <div className='section-title quill-cont text-start'>
                                <span className='short-title-2'> _WHY OFIN?</span>
                                <h1 className='mb-3'> {ofinData.title} </h1>
                                <div dangerouslySetInnerHTML={{ __html: ofinData.description }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Feature