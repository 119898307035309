import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import BorrowerHeader from './BorrowerHeader';
import { CopyRights } from '../pages/Footer';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const ApprovedRequest = () => {
    const [data, setData] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate()
    const [helmetData, setHelmetData] = useState([]);
    const slug = 'borrower-approved-request';
    useEffect(() => {
        axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setHelmetData(response.data.data);
                }
            })
    }, []);
    const getData = async () => {
        const userId = localStorage.getItem("userid");
        await axios.get(`${BASE_URL}requests?userId=${userId}&status=active`)
            .then(async (res) => {
                const requestData = res.data.data;
                const paymentDataPromises = requestData.map(async (request) => {
                    const response = await axios.get(`${BASE_URL}payment?userId=${userId}&reqId=${request._id}`);
                    return response.data.data;
                });
                const paymentDataList = await Promise.all(paymentDataPromises);
                // Merge payment data into request data
                const mergedData = requestData.map((request, index) => ({
                    ...request,
                    paidAmount: paymentDataList[index].reduce((total, payment) => total + payment.paidAmount, 0),
                }));
                setData(mergedData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }
    useEffect(() => {
        getData()
    }, [])
    const handleInvestorClick = (id) => {
        console.log("Clicked on investor with ID:", id);
        setModalShow({ show: true, invID: id });
    }
    const handlePayDue = (requestId) => {
        navigate(`/borrower-payments/${requestId}`);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(Math.ceil(data.length / perPage));
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [sortedColumn, setSortedColumn] = useState(null);
    const sortedData = useMemo(() => {
        const sortableData = [...data];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);
    const filteredData = useMemo(() => {
        return sortedData.filter(item => {
            // Exclude items where payable amount is 0 and match the search query
            const values = Object.values(item).map(val => val.toString().toLowerCase());
            return (
                values.some(val => val.includes(searchQuery.toLowerCase())) &&
                (item.amountRequired !== item.paidAmount) && (item.amountRequired - item.paidAmount !== 0)
            );
        });
    }, [sortedData, searchQuery]);
    const displayData = useMemo(() => {
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        return filteredData.slice(startIndex, endIndex);
    }, [currentPage, perPage, filteredData]);
    useEffect(() => {
        setTotalPages(Math.ceil(filteredData.length / perPage));
    }, [filteredData, perPage]);
    const Size = [10, 25, 50, 100];
    const handlePerPageChange = (event) => {
        const newPerPage = parseInt(event.target.value, 10);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        setSortConfig({ key, direction });
    };
    const upArrowColor = sortConfig.direction === 'ascending' ? 'sorting_asc' : 'sorting';
    const downArrowColor = sortConfig.direction === 'descending' ? 'sorting_desc' : 'sorting';
    const generatePageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : 'disabled'}`}>
                    <a href="#" data-dt-idx={i - 1} className="page-link" onClick={() => handlePageClick(i)}> {i} </a>
                </li>
            );
        }
        return pageItems;
    };
    const generateTableHead = () => {
        const columns = [
            { key: 'index', label: 'S.No' },
            { key: 'investorName', label: 'InvestorName' },
            { key: 'productName', label: 'ProductName' },
            { key: 'amountRequired', label: 'Amount (₹)' },
            { key: 'paidAmount', label: 'Paid Amount (₹)' },
            { key: 'balanceAmount', label: 'Balance Amount (₹)' },
            // { key: 'StartDate', label: 'Start Date' },
            // { key: 'EndDate', label: 'End Date' },
            { key: '', label: 'Payment' },
        ];
        return (
            <thead> <tr>
                {columns.map((column) => (<th key={column.key} className={`${upArrowColor} ${downArrowColor}`} onClick={() => handleSort(column.key)}> {column.label} </th>))}
            </tr> </thead>
        );
    };
    useEffect(() => {
        setSortedColumn(sortConfig.key);
    }, [sortConfig.key]);
    return (
        <>
            <Helmet>
                {helmetData.length > 0 && <title>{String(helmetData[0].metaTitle)}</title>}
                {helmetData.length > 0 && <meta name="description" content={String(helmetData[0].meta_desc)} />}
                {helmetData.length > 0 && <meta name="keywords" content={String(helmetData[0].keywords)} />}
            </Helmet>
            <header> <BorrowerHeader /> </header>
            <main>
                <section className="safe-deposit-area overflow-hidden pt-100 pb-155 bg_disable">
                    <div className="container">
                        <div className="row align-items-center gy-lg-0 gy-4 wow fadeInUp">
                            <div className="col-lg-12 ">
                                <div className="info-box">
                                    <div id="table_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className='dataTables_length' id='table_length'>
                                                    <label className='d-flex'> Show <select name="table_length" className="form-select form-select-sm w-auto ms-2 me-2"
                                                        onChange={handlePerPageChange} value={perPage} >
                                                        {Size.map((option, index) => (<option key={index} value={option}> {option} </option>))} </select> entries
                                                    </label>
                                                </div>
                                            </div>
                                            <div className=" search-filter col-sm-12 col-md-6  " >
                                                <div id="table_filter" className="dataTables_filter ">
                                                    <label className='d-flex justify-content-end' > Search:
                                                        <input type="text" className="form-control w-auto ms-2 form-control-sm" aria-controls="table" value={searchQuery}
                                                            onChange={handleSearchChange} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-sm-12">
                                                <table id='table' className='table table-responsive table-bordered example dt-responsive table-borderless table-thead-bordered nowrap table-align-middle card-table dataTable no-footer dtr-inline'>
                                                    {generateTableHead()}
                                                    <tbody>
                                                        {displayData.map((item, index) => (
                                                            <tr key={item._id}>
                                                                <td> {((currentPage - 1) * perPage) + index + 1} </td>
                                                                <td onClick={() => handleInvestorClick(item.invID)} className='company-name' >{item.investorName}</td>
                                                                <td>{item.productName}</td>
                                                                <td>{item.amountRequired}</td>
                                                                <td>{item.paidAmount}</td>
                                                                <td>{item.amountRequired - item.paidAmount}</td>
                                                                {/* <td>{item.StartDate}</td>   */}
                                                                {/* <td>{item.EndDate}</td>  */}
                                                                <td>
                                                                    <button className="pay-button" onClick={() => handlePayDue(item._id)} > Pay Due </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {displayData && !displayData.length && <tr> <td colSpan="10" className="text-center"> <div className="p-2">No matching records found</div> </td> </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info" id="table_info" role="status" aria-live="polite">
                                                    Showing {((currentPage - 1) * perPage) + 1} to {Math.min(currentPage * perPage, filteredData.length)} of {filteredData.length} entries
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers " id="table_paginate">
                                                    <ul className="pagination d-flex justify-content-end">
                                                        <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`} id="table_previous" >
                                                            <a href="#" className="page-link" onClick={handlePreviousPage} disabled={currentPage === 1}> Previous </a>
                                                        </li>
                                                        {generatePageItems()}
                                                        <li className={`paginate_button page-item next ${currentPage === totalPages ? 'disabled' : ''}`} id="table_next">
                                                            <a href="#" className="page-link" onClick={handleNextPage} disabled={currentPage === totalPages}> Next </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <MyVerticallyCenteredModal show={modalShow.show} onHide={() => setModalShow(false)} invID={modalShow.invID} />
            </main>
            <footer className=' footer footer-3 fixed-footer '> <CopyRights /> </footer>
        </>
    );
};
export default ApprovedRequest;

function MyVerticallyCenteredModal(props) {
    const [investorData, setInvestorData] = useState([]);
    const getData = async () => {
        const id = props.invID;
        console.log("Fetching data for investor ID:", id);
        await axios.get(`${BASE_URL}investor/${id}`)
            .then((res) => {
                console.log("Fetched data:", res.data);
                setInvestorData(res.data.data);
            })
            .catch((error) => {
                console.error("Error fetching investor data:", error);
            });
    };
    useEffect(() => {
        if (props.show && props.invID) {
            getData();
        }
    }, [props.show, props.invID]);
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton> <Modal.Title id="contained-modal-title-vcenter" className='ps-2'>{investorData.companyName}</Modal.Title> </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={6}>
                        <p className='p-2'> Contact Person : {investorData.contactPerson}</p>
                        <p className='p-2'> Email : {investorData.email}</p>
                        <p className='p-2'> Address : {investorData.address}</p>
                    </Col>
                    <Col sm={6}>
                        <p className='p-2'> Phone No: {investorData.phoneNo}</p>
                        <p className='p-2'> PAN No: {investorData.pan}</p>
                        <p className='p-2'> GSTIN No: {investorData.gstin}</p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
