import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { imgpath } from '../Files';

const Subscribe = () => {
    return (
        <section className='cta-area-3'>
            <Container>
                <Row>
                    <Col md={12} sm={12} className='cta-colnm'>
                        <div className='cta-4 cta-bg-primary'>
                            <Row className='align-items-center'>
                                <Col lg={6} sm={12}>
                                    <div className='cta-content wow fadeInRight'>
                                        <h2>Start your free trial !</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <div className='d-flex flex-column flex-sm-row'>
                                            <input type='email' className='form-control' placeholder='Enter Email Address' />
                                            <a href='/' className='input-append theme-btn theme-btn-lg ms-sm-3'>Subscribe</a>
                                        </div>
                                        <ul className='list-unstyled feature-list'>
                                            <li> <FontAwesomeIcon icon="fas fa-circle-check" /> Get 30 day free trial </li>
                                            <li> <FontAwesomeIcon icon="fas fa-circle-check" /> No Spamming </li>
                                        </ul>
                                    </div>
                                </Col>

                                <Col lg={6} sm={12} >
                                    <img className='cta-img img-fluid wow fadeInRight' src={imgpath.contactImg} alt='ofin' />
                                    <img className='shape img-fluid' src={imgpath.ctaImg} alt='ofin' />
                                </Col>
                            </Row>
                        </div>
                    </Col>

                </Row>
            </Container>

        </section>
    )
}

export default Subscribe