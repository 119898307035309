import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from "react-hook-form";
import { Form, Col, Row, Container } from "react-bootstrap";
import axios from "axios";
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const Contact = () => {
  const [data, setData] = useState([])
  const [helmetData, setHelmetData] = useState([])
  const { register, handleSubmit, formState: { errors }, reset, } = useForm({ mode: "all" });
  const [err, setErr] = useState();
  const saveMessages = (data) => {
    axios.post(BASE_URL + "messages/mail-us", data).then((res) => {
      if (res?.data?.result === true) {
        console.log(res.data.message);
        reset();
      } else if (res?.data?.result === false) {
        setErr(res.data.errors);
      }
    });
  };
  const getData = async () => {
    await axios.get(BASE_URL + "contact")
      .then((res) => {
        setData(res.data.data);
      })
  }
  const slug = 'contact';
  const gethelmetData = async () => {
    axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
      .then((res) => {
        setHelmetData(res.data.data);
      })
  }
  useEffect(() => {
    getData()
    gethelmetData();
  }, [])
  return (
    <>
      <Helmet>
        {helmetData.length > 0 && <title>{String(helmetData[0].metaTitle)}</title>}
        {helmetData.length > 0 && <meta name="description" content={String(helmetData[0].meta_desc)} />}
        {helmetData.length > 0 && <meta name="keywords" content={String(helmetData[0].keywords)} />}
      </Helmet>
      {/* Navigation */}
      <header className="App-header">
        <Header />
      </header>
      <main>
        {/* Breadcrumb area */}
        <section className="breadcrumb-area">
          <div className="breadcrumb-widget pt-145 pb-125">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="breadcrumb-content">
                    <h1> Contact </h1>
                    <ul>
                      <li> <a href="/">home</a> </li>
                      <li> Contact </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* Contact Section */}
        <section className="pt-140 pb-140 get-touch-area bg_white">
          <Container>
            <Row className='gy-4 gy-lg-0'>
              <Col lg={5} sm={12}>
                <div className="section-title text-start">
                  <h2>Get in touch with us.</h2>
                  <p>Want to get in touch? We'd love to hear from you. Here's how you can reach us...</p>
                </div>
                <Row className='get-in-touch-left-row'>
                  <Col sm={6}>
                    <div className='get-touch-box'>
                      <div className='icon'>
                        <img src={imgpath.callImg} alt='call icon' />
                      </div>
                      <div>
                        <p> Call the helpline: </p>
                        {/* <span>(02) 4003 7936 </span> */}
                        <span> {data.helplineNo} </span>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className='get-touch-box sm-mt-30'>
                      <div className='icon'>
                        <img src={imgpath.mailImg} alt='mail icon' />
                      </div>
                      <div>
                        <p> Email us: </p>
                        <span>  {data.email} </span>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className='get-touch-box mt-30'>
                      <div className='icon'>
                        <FontAwesomeIcon icon="fas fa-location-dot" />
                      </div>
                      <div>
                        <p> Location </p>
                        {/* <span> 1550 Bryant Street STE 750, <br/>San Francisco, CA 94103 </span> */}
                        <span> {data.location} </span>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className='get-touch-box mt-30'>
                      <div className='icon'>
                        <FontAwesomeIcon icon="far fa-clock" />
                      </div>
                      <div>
                        <p> Work Hours </p>
                        <span>  {data.workHours} </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={{ span: 6, offset: 1 }} sm={12}>
                <div className='contact-form-widget'>
                  <Form method='POST' onSubmit={handleSubmit(saveMessages)}>
                    <Row>
                      <Col md={6} sm={12}>
                        <Form.Group>
                          <Form.Label htmlFor="fullName" className="label">
                            Full Name*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="fullName"
                            placeholder="What's your name?"
                            className="form-control"
                            {...register("fullName", {
                              required: "Full Name is required",
                            })}
                            name="fullName"
                            isInvalid={errors?.fullName}
                          ></Form.Control>
                          {errors?.fullName && (
                            <span className="text-danger">
                              {errors.fullName.message}
                            </span>
                          )}
                          {err?.fullName && (
                            <span className="text-danger">{err?.fullName}</span>)}
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12} className='mt-20 mt-md-20'>
                        <Form.Group>
                          <Form.Label htmlFor="contactEmail" className="label">
                            Email*
                          </Form.Label>
                          <Form.Control
                            type="email"
                            id="contactEmail"
                            placeholder="yourname@example.com"
                            className="form-control"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: /^\S+@\S+$/,
                                message: "Invalid email address",
                              },
                              validate: (value) =>
                                value === value.toLowerCase() ||
                                "Email must contain only lowercase letters",
                            })}
                            isInvalid={errors?.email}
                          ></Form.Control>
                          {errors?.email && (
                            <span className="text-danger">
                              {errors.email.message}
                            </span>
                          )}
                          {err?.email && <span className="text-danger">{err?.email}</span>}
                        </Form.Group>
                      </Col>
                      <Col md={12} className='form_mt-20-top_colmn' >
                        <Form.Group>
                          <Form.Label htmlFor="subject" className="label">
                            Subject*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="subject"
                            placeholder="Your subject"
                            className="form-control"
                            {...register("subject", {
                              required: "Message Subject is required",
                            })}
                            name="subject"
                            isInvalid={errors?.subject}
                          ></Form.Control>
                          {errors?.subject && (
                            <span className="text-danger">
                              {errors.subject.message}
                            </span>
                          )}
                          {err?.subject && (
                            <span className="text-danger">{err?.subject}</span>)}
                        </Form.Group>
                      </Col>
                      <Col md={12} className='form_mt-20-top_colmn' >
                        <Form.Group>
                          <Form.Label htmlFor="messages" className="label">
                            Message*
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            id="messages"
                            cols={3} rows={5}
                            placeholder="Your message..."
                            className="form-control"
                            {...register("messages", {
                              required: "Message is required",
                            })}
                            name="messages"
                            isInvalid={errors?.messages}
                          ></Form.Control>
                          {errors?.messages && (
                            <span className="text-danger">
                              {errors.messages.message}
                            </span>
                          )}
                          {err?.messages && (
                            <span className="text-danger">{err?.messages}</span>)}
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <button type='submit' className='theme-btn theme-btn-lg w-100'> Submit Now </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      {/* Footer Section */}
      <footer className="App-footer">
        <Footer />
      </footer>
    </>
  )
}
export default Contact