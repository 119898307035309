import React from 'react';
import Headroom from 'react-headroom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { imgpath } from '../Files';
const InvestorHeader = () => {
  const location = useLocation();
  const handleLogout = () => {
    localStorage.removeItem('userid');
  };
  return (
    <Headroom style={{ zIndex: 99 }}>
      <div className='header-menu header-menu-4 navbar_fixed'>
        <Navbar expand="lg" className='bg_white' data-bs-theme="light">
          <Container>
            <Navbar.Brand as={Link} to="/" className='sticky_logo'>
              <img src={imgpath.ofinLogo} height="75" className="d-inline-block align-top" alt="Alumni logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav right-nav">
              <Nav className="mx-auto menu "  >
                <Nav.Item className='submenu'>
                  <Nav.Link as={Link} to="/investor" className={location.pathname === '/investor' ? 'active' : ''} > Dashboard</Nav.Link>
                </Nav.Item>
                <Nav.Item className='submenu'>
                  <Nav.Link as={Link} to="/investor-requests" className={location.pathname === '/investor-requests' ? 'active' : ''} > Requests</Nav.Link>
                </Nav.Item>
                <Nav.Item className='submenu'>
                  <Nav.Link as={Link} to="/investor-approved-request" className={location.pathname === '/investor-approved-request' ? 'active' : ''} > Approved Request</Nav.Link>
                </Nav.Item>
                <Nav.Item className='submenu'>
                  <Nav.Link as={Link} to="/investor-closedloans" className={location.pathname === '/investor-closedloans' ? 'active' : ''} > Closed Loans </Nav.Link>
                </Nav.Item>
              </Nav>
              <Link to="/" className=" theme-btn home-btn theme-btn-outlined">
                <FontAwesomeIcon icon="fa-solid fa-house" />
              </Link>
              <Link to="/login" className=" theme-btn" onClick={handleLogout}>
                Logout
              </Link>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </Headroom>
  )
}
export default InvestorHeader