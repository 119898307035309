import React, { useState, useEffect, useMemo } from 'react';
import BorrowerHeader from './BorrowerHeader';
import { CopyRights } from '../pages/Footer';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const RequestStatus = () => {
    const [data, setData] = useState([])
    const [helmetData, setHelmetData] = useState([]);
    const slug = 'borrower-request-status';
    useEffect(() => {
        axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setHelmetData(response.data.data);
                }
            })
    }, []);
    const getData = async () => {
        const userId = localStorage.getItem("userid");
        await axios.get(`${BASE_URL}requests?userId=${userId}`)
            .then((res) => {
                setData(res.data.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }
    useEffect(() => {
        getData()
    }, [])
    const handleDeleteReq = async (requestId) => {
        try {
            await axios.delete(`${BASE_URL}requests/${requestId}`);
            getData();
        } catch (error) {
            console.error("Error deleting request:", error);
        }
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(data.length));
    const [searchQuery, setSearchQuery] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [sortedColumn, setSortedColumn] = useState(null);
    const sortedData = useMemo(() => {
        const sortableData = [...data];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);
    const filteredData = useMemo(() => {
        return sortedData.filter(item => {
            const values = Object.values(item).map(val => (val ? val.toString().toLowerCase() : ''));
            return values.some(val => val.includes(searchQuery.toLowerCase()));
        });
    }, [sortedData, searchQuery]);
    const displayData = useMemo(() => {
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        return filteredData.slice(startIndex, endIndex);
    }, [currentPage, perPage, filteredData]);
    useEffect(() => {
        setTotalPages(Math.ceil(filteredData.length / perPage));
    }, [filteredData, perPage]);
    const Size = [10, 25, 50, 100];
    const handlePerPageChange = (event) => {
        const newPerPage = parseInt(event.target.value, 10);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        setSortConfig({ key, direction });
    };
    const upArrowColor = sortConfig.direction === 'ascending' ? 'sorting_asc' : 'sorting';
    const downArrowColor = sortConfig.direction === 'descending' ? 'sorting_desc' : 'sorting';
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const generatePageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : 'disabled'}`}>
                    <a href="#" className="page-link" onClick={() => handlePageClick(i)}> {i} </a>
                </li>
            );
        }
        return pageItems;
    };
    const getStatusBadgeColor = (status) => {
        return status === 'active' ? 'success' : 'warning';  // Always use 'warning' for 'disapprove'
    };
    const getStatusText = (status) => {
        // Show "Pending" for the status "disapprove"
        return status === 'disapprove' ? 'Pending' : status.charAt(0).toUpperCase() + status.slice(1);
    };
    const generateTableHead = () => {
        const columns = [
            { key: 'index', label: 'S.No' },
            { key: 'forcompanyName', label: 'CompanyName' },
            { key: 'companyType', label: 'CompanyType' },
            { key: 'teamSize', label: 'TeamSize' },
            { key: 'amountRequired', label: 'AmountRequired (₹)' },
            { key: 'productName', label: 'ProductName' },
            { key: 'status', label: 'Status' },
            { key: 'action', label: 'action' },
        ];
        return (
            <thead>
                <tr>
                    {columns.map((column) => (
                        <th key={column.key} className={`${upArrowColor} ${downArrowColor}`} onClick={() => handleSort(column.key)} >
                            {column.label}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    };
    useEffect(() => {
        setSortedColumn(sortConfig.key);
    }, [sortConfig.key]);
    return (
        <>
            <Helmet>
                {helmetData.length > 0 && <title>{String(helmetData[0].metaTitle)}</title>}
                {helmetData.length > 0 && <meta name="description" content={String(helmetData[0].meta_desc)} />}
                {helmetData.length > 0 && <meta name="keywords" content={String(helmetData[0].keywords)} />}
            </Helmet>
            <header>
                <BorrowerHeader />
            </header>
            <main>
                <section className="safe-deposit-area overflow-hidden pt-100 pb-155 bg_disable">
                    <div className="container">
                        <div className="row align-items-center gy-lg-0 gy-4 wow fadeInUp" >
                            <div className="col-lg-12  p-0">
                                <div className="info-box">
                                    <div id="table_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className='dataTables_length' id='table_length'>
                                                    <label className='d-flex'> Show
                                                        <select name="table_length"
                                                            aria-controls="table"
                                                            className="form-select form-select-sm w-auto ms-2 me-2"
                                                            onChange={handlePerPageChange}
                                                            value={perPage} >
                                                            {Size.map((option, index) => (
                                                                <option key={index} value={option}> {option}
                                                                </option>
                                                            ))}
                                                        </select> entries</label>
                                                </div>
                                            </div>
                                            <div className=" search-filter col-sm-12 col-md-6  " >
                                                <div id="table_filter" className="dataTables_filter ">
                                                    <label className='d-flex justify-content-end' > Search:
                                                        <input type="text"
                                                            className="form-control w-auto ms-2 form-control-sm"
                                                            aria-controls="table"
                                                            value={searchQuery}
                                                            onChange={handleSearchChange} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row dt-row mt-3">
                                            <div className="col-sm-12">
                                                <table id="table" className='table table-responsive table-bordered example dt-responsive table-borderless table-thead-bordered nowrap table-align-middle card-table dataTable no-footer dtr-inline'>
                                                    {generateTableHead()}
                                                    <tbody>
                                                        {displayData.map((item, index) => (
                                                            <tr key={item._id}>
                                                                <td> {((currentPage - 1) * perPage) + index + 1} </td>
                                                                <td>{item.forcompanyName}</td>
                                                                <td>{item.companyType}</td>
                                                                <td>{item.teamSize}</td>
                                                                <td>{item.amountRequired}</td>
                                                                <td>{item.productName}</td>
                                                                <td>
                                                                    {/* <span className={`badge bg-${item.status === 'active' ? 'success' : item.status === 'pending' ? 'warning' : 'danger'}`}>{item.status}</span> */}
                                                                    <span className={`badge bg-${getStatusBadgeColor(item.status)}`}> {getStatusText(item.status)} </span>
                                                                </td>
                                                                {/* <td>
                                                                <a href="javascript:;" className="badge bg-danger" >
                                                                    <img src={require("../assets/img/icons/trash.png")} alt="Delete Icon" className="img-fluid" />
                                                                </a>
                                                            </td> */}
                                                                <td>
                                                                    {item.status !== 'active' && (
                                                                        <a href="#" className="badge bg-danger" onClick={() => handleDeleteReq(item._id)}>
                                                                            <img src={imgpath.deleteIcon} alt="Delete Icon" className="img-fluid" />
                                                                        </a>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {displayData && !displayData.length &&
                                                            <tr>
                                                                <td colSpan="10" className="text-center">
                                                                    <div className="p-2">No matching records found</div>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info" id="table_info" role="status" aria-live="polite"> Showing {((currentPage - 1) * perPage) + 1} to {Math.min(currentPage * perPage, filteredData.length)} of {filteredData.length} entries
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers " id="table_paginate">
                                                    <ul className="pagination d-flex justify-content-end">
                                                        <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`} id="table_previous" >
                                                            <a href="#" className="page-link" onClick={handlePreviousPage} disabled={currentPage === 1}>
                                                                Previous
                                                            </a>
                                                        </li>
                                                        {generatePageItems()}
                                                        <li className={`paginate_button page-item next ${currentPage === totalPages ? 'disabled' : ''}`} id="table_next">
                                                            <a href="#" className="page-link" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                                                Next
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </main>
            <footer className=' footer footer-3 fixed-footer '>
                <CopyRights />
            </footer>
        </>
    );
};
export default RequestStatus;
