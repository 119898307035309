import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const Faq = () => {
    const [data, setData] = useState([])
    const getData = async () => {
        await axios.get(BASE_URL + "faq")
            .then((res) => {
                setData(res.data.data);
            })
    }
    useEffect(() => {
        getData()

    }, [])
    return (
        <section className='faq-area-3'>
            <Container>
                <Row className='gy-lg-0 gy-4'>
                    <Col lg={6}>
                        <img className='faq-img img-fluid' src={imgpath.faqImg} alt='ofin' />
                    </Col>
                    <Col lg={6} className='faq-colm-right'>
                        <span className='subtitle'>_FAQ</span>
                        <h1> Frequently asked general <span className='underline-shape'> questions </span> </h1>
                        <div className='faq-widget'>
                            <Accordion className='accord-section'>
                                {data.map((info) => (
                                    <Accordion.Item eventKey={info._id} key={info._id} className='single-faq'>
                                        <Accordion.Header className='faq-header mb-0'> {info.title}</Accordion.Header>
                                        <Accordion.Body className='collapse show'>
                                            <div className='faq-body'>
                                                {info.description}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))
                                }
                            </Accordion>
                            <h6 className='more-contact'> Have more question ? <Link to='/contact'> Contact Us </Link> </h6>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Faq