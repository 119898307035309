import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { Link } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const Blog = () => {
  const [data, setData] = useState([])
  const getData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}news?home_News=true`);
      setData(res.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getData()
  }, [])
  return (
    <section className='news-area-2'>
      <Container>
        <div className='section-title'>
          <span className='short-title-2'> _News</span>
          <h1 className='mb-3 wow fadeInUp'> Our Latest <span className='underline-shape'> articles</span> </h1>
          <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration in some form,</p>
        </div>
        <Row className='gy-lg-0 gy-4 news-row-sec'>
          {
            data.map((blogs) => {
              return (
                <Col lg={4} md={6} sm={12} key={blogs._id}>
                  <div className='blog-widget-3 wow fadeInUp'  >
                    <Link to={`/newsdetails/${blogs.slug}`} className='blog-img d-block'> <img src={BASE_URL + `news/uploads/${blogs.profile}`} alt='blog-img' /> </Link>
                    <div className='blog-content'> <h4><Link to={`/newsdetails/${blogs.slug}`} > {blogs.title} </Link></h4> </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}
export default Blog