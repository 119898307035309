import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import { useForm } from "react-hook-form";
import { Form, Col, Container, Row, } from "react-bootstrap";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "all" });
  const navigate = useNavigate();
  const [err, setErr] = useState()
  const logIn = (data) => {
    axios.post(BASE_URL + 'user-login', data)
      .then((res) => {
        if (res?.data?.result === true) {
          const userType = res.data.data.userType;
          setTimeout(() => {
            toast.success(res.data.message, { theme: "colored" });
          }, 100);
          setErr("");
          if (userType === 'investor') {
            // Redirect to the investor page
            localStorage.setItem('userid', res.data.data.investor._id);
            navigate("/investor");
          } else if (userType === 'borrower') {
            // Redirect to the borrower page
            localStorage.setItem('userid', res.data.data.borrower._id);
            navigate("/borrower");
          } else {
            // Handle other user types or scenarios
            console.error("Unknown user type:", userType);
          }
          console.log(res.data.message)
        }
        else if (res?.data?.result === false) {
          setErr(res.data.errors);
        }
      })
  }
  const [data, setData] = useState([]);
  const slug = 'login';
  useEffect(() => {
    axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      })
  }, []);
  return (
    <>
      <Helmet>
        {data.length > 0 && <title>{String(data[0].metaTitle)}</title>}
        {data.length > 0 && <meta name="description" content={String(data[0].meta_desc)} />}
        {data.length > 0 && <meta name="keywords" content={String(data[0].keywords)} />}
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <section className='breadcrumb-area'>
          <div className='breadcrumb-widget pt-145 pb-125'>
            <Container>
              <Row>
                <Col md={12}>
                  <div className='breadcrumb-content'>
                    <h1> Login </h1>
                    <ul>
                      <li> <a href='/'>home</a> </li>
                      <li> Login </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* Login Section */}
        <section className='loan-details-area bg_disable pt-130 pb-100'>
          <Container>
            <Row className='justify-content-center'>
              <Col lg={5}>
                <div className='loan-details-widget login-form'>
                  <Form autoComplete="off" onSubmit={handleSubmit(logIn)} >
                    <Row className='gy-3'>
                      <Col md={12}>
                        <Form.Group >
                          <Form.Label htmlFor="signemail" className='label'> Email* </Form.Label>
                          <Form.Control type="email" className='form-control' id="signemail"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: /^\S+@\S+$/,
                                message: "Invalid email address",
                              },
                              validate: (value) =>
                                value === value.toLowerCase() ||
                                "Email must contain only lowercase letters",
                            })}
                            isInvalid={errors?.email} ></Form.Control>
                          {errors?.email && (
                            <span className="text-danger"> {errors.email.message} </span>
                          )}
                          {err?.email &&
                            <span className="text-danger">{err?.email}</span>
                          }
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group >
                          <Form.Label htmlFor="password" className='label'>Password*</Form.Label>
                          <Form.Control type="password" id="password" className='form-control'
                            {...register("password", {
                              required: "Password is Required",
                              minLength: {
                                value: 8,
                                message: "Password must be minimum 8 characters",
                              },
                              validate: (value) => {
                                const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
                                return (
                                  regex.test(value) ||
                                  "Password must contain atleast one uppercase,symbol and number"
                                );
                              },
                            })}
                            isInvalid={errors?.password} />
                          {errors?.password && (
                            <span className="text-danger">
                              {errors.password.message}
                            </span>
                          )}
                          {err?.password &&
                            <span className="text-danger">{err?.password}</span>
                          }
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='login-form-2-row'>
                      <Col md={12} className='text-center'>
                        <button type='submit' className='theme-btn'> Submit </button>
                        <p className='mb-0'> Don't have an account? <Link to='/signup' className='register-btn'> Sign up </Link> </p>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      {/* Footer Section */}
      <footer className="App-footer">
        <Footer />
      </footer>
    </>
  )
}
export default Login