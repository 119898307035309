import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CopyRights } from "../pages/Footer";
import BorrowerHeader from "./BorrowerHeader";
import axios from "axios";
import Card from 'react-bootstrap/Card';
import "react-datepicker/dist/react-datepicker.css";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const Payments = () => {
    const [data, setData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate()
    const getData = async () => {
        await axios.get(BASE_URL + `requests/${id} `)
            .then((res) => {
                setData(res.data.data);
            })
    }
    const fetchPaymentData = async () => {
        try {
            const reqId = data._id
            const userId = localStorage.getItem("userid");
            const response = await axios.get(`${BASE_URL}payment?userId=${userId}&reqId=${reqId}`);
            setPaymentData(response.data.data);
        } catch (error) {
            console.error("Error fetching payment data:", error);
        }
    };
    useEffect(() => {
        getData()
    }, []
    )
    useEffect(() => {
        if (data._id) {
            fetchPaymentData();
        }
    }, [data]);
    const memoizedPaymentData = useMemo(() => paymentData, [paymentData]);
    const payableAmount = data.amountRequired - memoizedPaymentData.reduce((total, item) => total + item.paidAmount, 0);
    const totalpaidAmount = memoizedPaymentData.reduce((total, item) => total + item.paidAmount, 0);
    const handlePayDue = (requestId) => {
        navigate(`/borrower-paynow/${requestId}`);
    };
    const isPaymentComplete = totalpaidAmount === data.amountRequired;
    const generateTableHead = () => {
        const columns = [
            { key: 'index', label: 'S.No' },
            { key: 'paidDate', label: 'Paid Date' },
            { key: 'paidAmount', label: 'Paid Amount (₹)' },
            { key: 'paymentMode', label: 'Payment Mode' },
            { key: 'referenceNumber', label: 'Reference Number' },
        ];
        return (
            <thead> <tr> {columns.map((column) => (<th key={column.key}> {column.label} </th>))} </tr> </thead>
        );
    };
    return (
        <>
            <header> <BorrowerHeader /> </header>
            <main>
                <section className="safe-deposit-area overflow-hidden pt-100 pb-155 bg_disable">
                    <div className="container">
                        <div className="row align-items-center gy-lg-0 gy-4 wow fadeInUp ">
                            <div className="col-lg-12 ">
                                <div className="row pb-3 m-1">
                                    <div className={`col-sm-12 col-md-${isPaymentComplete ? 6 : 4} ps-0`}>
                                        <span > Investor Name :  </span>
                                        <span className='investor-name'> {data.investorName} </span>
                                    </div>
                                    <div className={`search-filter col-sm-12 ${isPaymentComplete ? 'col-md-6 text-end' : 'pe-0 col-md-4 text-center'}`}>
                                        <span> Product Name :  </span>
                                        <span className='investor-name '> {data.productName} </span>
                                    </div>
                                    <div className=" search-filter col-sm-12 col-md-4  text-end" >
                                        {!isPaymentComplete && (
                                            <button className="theme-btn theme-btn-outlined" onClick={() => handlePayDue(data._id)} > Pay Now </button>)}
                                    </div>
                                </div>
                                <div className="info-box">
                                    <div id="table_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        {!isPaymentComplete && (
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4  ">
                                                    <Card className='payment-details-card ps-4 pe-4 pt-3 pb-2' >
                                                        <Card.Text className='mb-1' >  Borrowed Amount: </Card.Text>
                                                        <Card.Title >  {data.amountRequired} ₹ </Card.Title>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-12 col-md-4 ">
                                                    <Card className='payment-details-card ps-4 pe-4 pt-3 pb-2' >
                                                        <Card.Text className='mb-1' >  Total Amount Paid: </Card.Text>
                                                        <Card.Title >  {totalpaidAmount} ₹ </Card.Title>
                                                    </Card>
                                                </div>
                                                <div className=" search-filter col-sm-12 col-md-4  " >
                                                    <Card className='payment-details-card ps-4 pe-4 pt-3 pb-2' >
                                                        <Card.Text className='mb-1' >  Payable Amount: </Card.Text>
                                                        <Card.Title >  {payableAmount} ₹ </Card.Title>
                                                    </Card>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row dt-row mt-3">
                                            <div className="col-sm-12">
                                                <table id="table" className='table table-responsive table-bordered example dt-responsive table-borderless table-thead-bordered nowrap table-align-middle card-table dataTable no-footer dtr-inline'>
                                                    {generateTableHead()}
                                                    <tbody>
                                                        {memoizedPaymentData
                                                            .slice() // create a shallow copy to avoid mutating the original array
                                                            .sort((a, b) => new Date(b.paidDate) - new Date(a.paidDate)) // sort by paidDate in descending order
                                                            .map((item, index) => (
                                                                <tr key={item.id}>
                                                                    <td> {index + 1} </td>
                                                                    <td>{item.paidDate}</td>
                                                                    <td>{item.paidAmount}</td>
                                                                    <td>{item.paymentMode}</td>
                                                                    <td>{item.referenceNumber}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className=" footer footer-3 fixed-footer ">
                <CopyRights />
            </footer>
        </>
    );
};
export default Payments;
