import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const InvSwiper = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}investor-logo`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      })
  }, []);
  return (
    <>
      <section className="saas-clients-logo pt-100 pb-100 bg_disable">
        <Container>
          <Col lg={6} className='mx-auto mb-50'>
            <div className='section-title'>
              <h2 className='wow fadeInUp'> Our investors </h2>
            </div>
          </Col>
          <Row className='gy-md-0 gy-4 align-items-center pt-30 h-100'>
            <Swiper
              spaceBetween={30}
              slidesPerView={5}
              loop={true}
              breakpoints={{
                1600: { slidesPerView: 5, },
                992: { slidesPerView: 5, },
                991: { slidesPerView: 4, },
                768: { slidesPerView: 4, },
                767: { slidesPerView: 3, },
                575: { slidesPerView: 3, },
                320: { slidesPerView: 3, },
              }}
              autoplay={{ delay: 1000, disableOnInteraction: false, }}
              modules={[Autoplay]} >
              {
                data.map((logo) => {
                  return (
                    <SwiperSlide key={logo._id}>
                      <img className='clients-logo img-fluid' src={`${BASE_URL}investor-logo/uploads/${logo.investorLogo}`} alt='client' />
                    </SwiperSlide>
                  )
                })}
            </Swiper>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default InvSwiper