import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { imgpath } from '../Files';
export const Footer = () => {
  return (
    <div>
      <div className='footer-top pb-3'>
        <Container>
          <Row className='gx-0 footer-top-row'>
            <Col xl={4} lg={4} md={4} sm={6} className='text-center text-sm-start ms-0'>
              <div className='footer-widget wow fadeInLeft'>
                <a href='#' className='p-0 m-0'> <img src={imgpath.footerLogo} alt='logo' height="75" /> </a>
                <div className='footer-text'>
                  <p> Ofin is a leading bank in the worldzone and a prominent international banking institution </p>
                </div>
              </div>
            </Col>
            <Col lg={2} sm={6} className='text-center footer-widget-22 text-sm-start ms-lg-5 ' >
              <div className='footer-widget wow fadeInUp'>
                <div className='f-widget-title'>
                  <h5>Ofin At A Glance</h5>
                </div>
                <div className='footer-link'>
                  <ul>
                    <li> <a href='#'> Our core Businesses </a> </li>
                    <li> <a href='#'> Our 'company purpose' </a> </li>
                    <li> <a href='#'> Jobs & Careers </a> </li>
                    <li> <a href='#'> Our Responsibility </a> </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={2} sm={6} className='text-center text-sm-start ms-lg-5' >
              <div className='footer-widget wow fadeInUp'>
                <div className='f-widget-title'>
                  <h5>Publications</h5>
                </div>
                <div className='footer-link'>
                  <ul>
                    <li> <a href='#'> Compliance Publications </a> </li>
                    <li> <a href='#'> Annual Reports </a> </li>
                    <li> <a href='#'> CSR Reports </a> </li>
                    <li> <a href='#'> Financial documentation </a> </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={2} sm={6} className='text-center text-sm-start ms-lg-5 me-0'  >
              <div className='footer-widget wow fadeInUp'>
                <div className='f-widget-title'>
                  <h5>Direct Access</h5>
                </div>
                <div className='footer-link'>
                  <ul>
                    <li> <a href='#'> Our news </a> </li>
                    <li> <a href='#'> Our press releases </a> </li>
                    <li> <a href='#'> Our job offers </a> </li>
                    <li> <a href='#'> Our websites </a> </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Copy Rights Section */}
      <section>
        <CopyRights />
      </section>
    </div>
  )
}
export default Footer;
// Copyrights section for footer
export const CopyRights = () => {
  return (
    <div className='copyrights py-2'>
      <Container>
        <Row className='align-items-center'>
          <Col lg={6} className='my-3 my-sm-0 text-center text-lg-start'>
            <div className='copyright-text'>
              <p>Copyright © Ofin All rights reserved. <br className="d-sm-none" /> <a href='#' className='ms-2'> Privacy </a> | <a href='#' className='ms-0'>Term of Use </a> </p>
            </div>
          </Col>
          <Col lg={6} className='text-center text-lg-end'>
            <div className='social-button'>
              <a href='#'>
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={['fab', 'pinterest-p']} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}