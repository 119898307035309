import React, { useState, useEffect } from 'react';
import BorrowerHeader from './BorrowerHeader';
import Dashboard from './Dashboard';
import { CopyRights } from '../pages/Footer';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const BorrowerHome = () => {
  const [data, setData] = useState([]);
  const slug = 'borrower';
  useEffect(() => {
    axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      })
  }, []);
  return (
    <>
      <Helmet>
        {data.length > 0 && <title>{String(data[0].metaTitle)}</title>}
        {data.length > 0 && <meta name="description" content={String(data[0].meta_desc)} />}
        {data.length > 0 && <meta name="keywords" content={String(data[0].keywords)} />}
      </Helmet>
      <header> <BorrowerHeader /> </header>
      <main> <Dashboard /> </main>
      <footer className=' footer footer-3 fixed-footer '> <CopyRights /> </footer>
    </>
  )
}

export default BorrowerHome;
