import React, { useState, useEffect } from 'react'
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import { Autoplay, EffectCoverflow, Navigation } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const Products = () => {
  const [data, setData] = useState([]);
  const slug = 'products';
  useEffect(() => {
    axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      })
  }, []);
  return (
    <>
      <Helmet>
        {data.length > 0 && <title>{String(data[0].metaTitle)}</title>}
        {data.length > 0 && <meta name="description" content={String(data[0].meta_desc)} />}
        {data.length > 0 && <meta name="keywords" content={String(data[0].keywords)} />}
      </Helmet>
      {/* Navigation */}
      <header className="App-header">
        <Header />
      </header>
      <main>
        {/* Banner Section */}
        <section className="banner-area-3 pt-90">
          <div className="bg-slides">
            <div className="slide">
              <img className="wow slideInRight" src={imgpath.slide1} alt="img" />
            </div>
            <div className="slide">
              <img className="wow slideInRight" src={imgpath.slide1} alt="img" />
            </div>
            <div className="slide">
              <img className="wow slideInRight" src={imgpath.slide1} alt="img" />
            </div>
          </div>
          <Container>
            <Row className="align-items-end">
              <Col xl={7} lg={6} md={12} sm={12} className="pt-100 pt-lg-180 pb-lg-180 pb-100" >
                <div className="banner-content pb-20 pt-20">
                  <h1 className="wow fadeInUp"> Compare Loans From Several Banks Find The Cheapest Loan </h1>
                  <Link to="/" className="wow fadeInUp mt-50 theme-btn theme-btn-rounded-2 theme-btn-lg theme-btn-alt" > Apply Now
                    <FontAwesomeIcon icon="fas fa-arrow-right" className='products-buton-arrow' /> </Link>
                </div>
              </Col>
              <Col xl={5} lg={6} md={12} sm={12} className='personimg-colm'>
                <img src={imgpath.girlImg} className="person-img" alt="person" />
              </Col>
            </Row>
          </Container>
        </section>
        {/* transactions counts */}
        <section className="pt-100 pb-100 overflow-hidden statistic-section bg_white">
          <Container>
            <Row>
              <Col lg={4} md={6} sm={12} className="text-center">
                <h3>450+</h3>
                <p> Transactions</p>
              </Col>
              <Col lg={4} md={6} sm={12} className="text-center">
                <h3>INR 27000+ Cr</h3>
                <p> GTV Enabled</p>
              </Col>
              <Col lg={4} md={6} sm={12} className="text-center">
                <h3>75000+ Cr</h3>
                <p> Portfolio Monitoring</p>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Help ad section */}
        <section className="help-ad-area pt-100 pb-100 overflow-hidden bg_disable">
          <Container>
            <Row className="pt-40">
              <Col lg={8} sm={12} className="mx-auto mb-50">
                <div className="section-title">
                  <h2 className="wow fadeInUp"> API-based Architecture For Quick Integration With Lending Ecosystem Enablers </h2>
                  <p className='mb-0'> One-time API integration to simplify the flow of data between Originators and Lenders </p>
                </div>
              </Col>
              <Col lg={8} sm={12}>
                <Row className="justify-content-center">
                  <Col lg={10} className="text-center">
                    <video loop autoPlay='autoplay' controls height="480" width="100%">
                      <source type="video/mp4" src={imgpath.video_ofin} />
                      <track kind="captions" srcLang="en" label="English captions" src={imgpath.video_ofin} />
                    </video>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} sm={12} className="align-self-center">
                <ul className="ps-0">
                  <li> <span> <FontAwesomeIcon icon="fa fa-check" /> </span> Offers from several banks </li>
                  <li> <span> <FontAwesomeIcon icon="fas fa-check" /> </span> Free & no obligation </li>
                  <li> <span> <FontAwesomeIcon icon="fas fa-check" /> </span> Compare safely and securely </li>
                  <li> <span> <FontAwesomeIcon icon="fas fa-check" /> </span> Offer within a few hours </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        {/* FUlly integrated content section */}
        <section className="manage-c-finance pt-100 pb-100 bg_white">
          <Container>
            <Row className="text-center">
              <div className="col-lg-8 mx-auto section-title">
                <h2 className="wow fadeInUp"> A Fully-integrated Securitisation Solution </h2>
                <p className="wow fadeInUp mb-0"> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Exercitationem, voluptates illo ducimus quos facere perferendis. </p>
              </div>
            </Row>
            <Row className="gy-4 gy-xl-0 pt-60">
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget-2 wow fadeInUp">
                  <div className="icon-bg-1">
                    <img src={imgpath.icon3} alt="icon" />
                  </div>
                  <h5> Freelancers </h5>
                  <p> The best business account to send and receive payments on a daily basis. </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget-2 wow fadeInUp">
                  <div className="icon-bg-2">
                    <img src={imgpath.icon2} alt="icon" />
                  </div>
                  <h5> SMBs & Startups </h5>
                  <p> Optimize your team's expenses by always staying in control. </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget-2 wow fadeInUp">
                  <div className="icon-bg-3">
                    <img src={imgpath.icon3} alt="icon" />
                  </div>
                  <h5> Business Founders </h5>
                  <p> Open a business account for the online deposit of your share capital. </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget-2 wow fadeInUp">
                  <div className="icon-bg-4">
                    <img src={imgpath.icon4} alt="icon" />
                  </div>
                  <h5> Microbusinesses </h5>
                  <p> Stay focused on your core business by managing your finances and accounting. </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Trusted Partners swiper section */}
        <section className="saas-clients-logo products-page pt-100 pb-100 bg_disable">
          <Container>
            <Col lg={6} className="mx-auto mb-50">
              <div className="section-title">
                <h2 className='wow fadeInUp'> Trusted by Leading Banking Partners and 750+ Lenders </h2>
              </div>
            </Col>
            <Row className="gy-md-0 gy-4 align-items-center pt-30 h-100">
              <Swiper
                spaceBetween={30}
                slidesPerView={5}
                loop={true}
                breakpoints={{
                  992: {
                    slidesPerView: 5,
                  },
                  991: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  767: {
                    slidesPerView: 3,
                  },
                  575: {
                    slidesPerView: 3,
                  },
                  320: {
                    slidesPerView: 3,
                  },
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]} >
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor1} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor2} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor3} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor4} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor5} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor1} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor2} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor3} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor4} alt="client" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="clients-logo img-fluid" src={imgpath.investor5} alt="client" />
                </SwiperSlide>
              </Swiper>
            </Row>
          </Container>
        </section>
        {/* Comprehensive product section */}
        <section className="comp-feature-area pt-100 pb-100 bg_white">
          <Container>
            <Row>
              <Col lg={6} className="mx-auto">
                <div className="section-title">
                  <h2 className="wow fadeInUp"> Comprehensive Product </h2>
                  <p className="wow fadeInUp mb-0"> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui quaerat nesciunt assumenda </p>
                </div>
              </Col>
            </Row>
            <Row className="gy-4 gy-xl-0 mt-5">
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src={imgpath.icon1} alt="icon" />
                  </div>
                  <h4> Prepaid Card </h4>
                  <p> It is a long established fact that a reader will be distracted by the readable content </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src={imgpath.icon2} alt="icon" />
                  </div>
                  <h4> Easy To Use </h4>
                  <p> The way to solve the word placement to treat the phrase "easy to use" as a single. </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src={imgpath.icon3} alt="icon" />
                  </div>
                  <h4> Save Your Card </h4>
                  <p> We keep your information safe and notify you anytime your saved payment card. </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src={imgpath.icon4} alt="icon" />
                  </div>
                  <h4> Pay Bill </h4>
                  <p> That's why we have a wide range of bill payment options through our Banking service. </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Simplified Process section */}
        <section className="comp-feature-area pt-100 pb-100 bg_disable">
          <Container>
            <Row>
              <Col lg={6} className="mx-auto">
                <div className="section-title">
                  <h2 className="wow fadeInUp"> Simplified Process </h2>
                  <p className="wow fadeInUp mb-0"> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui quaerat nesciunt assumenda </p>
                </div>
              </Col>
            </Row>
            <Row className="gy-4 gy-xl-0 mt-5">
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src={imgpath.icon1} alt="icon" />
                  </div>
                  <h4> Prepaid Card </h4>
                  <p> It is a long established fact that a reader will be distracted by the readable content </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src={imgpath.icon2} alt="icon" />
                  </div>
                  <h4> Easy To Use </h4>
                  <p> The way to solve the word placement to treat the phrase "easy to use" as a single. </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src={imgpath.icon3} alt="icon" />
                  </div>
                  <h4> Save Your Card </h4>
                  <p> We keep your information safe and notify you anytime your saved payment card. </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={12}>
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src={imgpath.icon4} alt="icon" />
                  </div>
                  <h4> Pay Bill </h4>
                  <p> That's why we have a wide range of bill payment options through our Banking service. </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* bcard-feature section */}
        <section className="bcard-feature-area pt-100 pb-115 bg_white">
          <Container>
            <Row>
              <Col lg={6} className="mx-auto">
                <div className="section-title">
                  <h2 className="wow fadeInUp"> Lorem ipsum dolor sit amet </h2>
                </div>
              </Col>
            </Row>
            <Row className="gy-4 feature-card-widget-5-top">
              <Col xl={2} lg={4} md={4}>
                <div className="feature-card-widget-5 text-center wow fadeInUp">
                  <img src={imgpath.icon11} alt="icon" />
                  <h6 className="mt-30"> Online banking</h6>
                </div>
              </Col>
              <Col xl={2} lg={4} md={4}>
                <div className="feature-card-widget-5 text-center wow fadeInUp">
                  <img src={imgpath.icon12} alt="icon" />
                  <h6 className="mt-30"> Up to 20.000 limit </h6>
                </div>
              </Col>
              <Col xl={2} lg={4} md={4}>
                <div className="feature-card-widget-5 text-center wow fadeInUp">
                  <img src={imgpath.icon13} alt="icon" />
                  <h6 className="mt-30"> Protection & security </h6>
                </div>
              </Col>
              <Col xl={2} lg={4} md={4}>
                <div className="feature-card-widget-5 text-center wow fadeInUp">
                  <img src={imgpath.icon14} alt="icon" />
                  <h6 className="mt-30"> Mobile application </h6>
                </div>
              </Col>
              <Col xl={2} lg={4} md={4}>
                <div className="feature-card-widget-5 text-center wow fadeInUp">
                  <img src={imgpath.icon15} alt="icon" />
                  <h6 className="mt-30"> Online Shopping </h6>
                </div>
              </Col>
              <Col xl={2} lg={4} md={4}>
                <div className="feature-card-widget-5 text-center wow fadeInUp">
                  <img src={imgpath.icon16} alt="icon" />
                  <h6 className="mt-30"> Small payments fees </h6>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* customer Testimonial */}
        <section className='pt-100 pb-100 testimonial-area bg_disable'>
          <Container fluid className='px-0'>
            <Col lg={6} className="mx-auto">
              <div className="section-title">
                <h2 className="wow fadeInUp"> Meet our happy customers </h2>
                <p className="wow fadeInUp mb-0"> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui quaerat nesciunt assumenda </p>
              </div>
            </Col>
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Autoplay, EffectCoverflow, Navigation]}
              className="testimonial-slider mt-4 mb-5 pb-5 " >
              <SwiperSlide className='container'>
                <div className="testimonial-widget ">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="author-img">
                        <img src={imgpath.customer1} alt="imag" className='w-sm-100' />
                      </div>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <div className="testimonial-content">
                        <h2>Making dreams a reality!</h2>
                        <p className="pr-lg-60">We were looking for a home of happiness and peace. Thanks to the Grihashakti team, who helped us to realise this dream of ours. Our home has been very lucky for us – as we shifted to our new home, prosperity followed! </p>
                        <div className="author-info">
                          <h4>Maxwell Wood</h4>
                          <span>New York, US</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FontAwesomeIcon icon="fas fa-quote-right" />
                </div>
              </SwiperSlide>
              <SwiperSlide className='container'>
                <div className="testimonial-widget ">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="author-img">
                        <img src={imgpath.customer2} alt="imag" />
                      </div>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <div className="testimonial-content">
                        <h2>Making dreams a reality!</h2>
                        <p className="pr-lg-60">We were looking for a home of happiness and peace. Thanks to the Grihashakti team, who helped us to realise this dream of ours. Our home has been very lucky for us – as we shifted to our new home, prosperity followed! </p>
                        <div className="author-info">
                          <h4>Maxwell Wood</h4>
                          <span>New York, US</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FontAwesomeIcon icon="fas fa-quote-right" />
                </div>
              </SwiperSlide>
              <SwiperSlide className='container'>
                <div className="testimonial-widget ">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="author-img">
                        <img src={imgpath.customer1} alt="imag" />
                      </div>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <div className="testimonial-content">
                        <h2>Making dreams a reality!</h2>
                        <p className="pr-lg-60">We were looking for a home of happiness and peace. Thanks to the Grihashakti team, who helped us to realise this dream of ours. Our home has been very lucky for us – as we shifted to our new home, prosperity followed! </p>
                        <div className="author-info">
                          <h4>Maxwell Wood</h4>
                          <span>New York, US</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FontAwesomeIcon icon="fas fa-quote-right" />
                </div>
              </SwiperSlide>
              <SwiperSlide className='container'>
                <div className="testimonial-widget ">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="author-img">
                        <img src={imgpath.customer2} alt="imag" />
                      </div>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <div className="testimonial-content">
                        <h2>Making dreams a reality!</h2>
                        <p className="pr-lg-60">We were looking for a home of happiness and peace. Thanks to the Grihashakti team, who helped us to realise this dream of ours. Our home has been very lucky for us – as we shifted to our new home, prosperity followed! </p>
                        <div className="author-info">
                          <h4>Maxwell Wood</h4>
                          <span>New York, US</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FontAwesomeIcon icon="fas fa-quote-right" />
                </div>
              </SwiperSlide>
            </Swiper>
          </Container>
        </section>
        {/* FAQ Section */}
        <section className='faq-area-2 pt-125 pb-100 bg_white'>
          <Container>
            <div className='section-title'>
              <h2 className='wow fadeInUp'> Frequently asked questions </h2>
            </div>
            <Row>
              <Col lg={10} className='mx-auto'>
                <div className='faq-widget'>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className='single-widget-one wow fadeInUp px-sm-5 px-4'>
                      <div className="widget-icon ">
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" />
                      </div>
                      <div className='w-100'>
                        <Accordion.Header className='faq-header'> Can I pay off my loan early? </Accordion.Header>
                        <Accordion.Body className='collapse show '>
                          <div className='faq-body pr-lg-130'>
                            <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className='single-widget-one wow fadeInUp px-sm-5 px-4'>
                      <div className="widget-icon ">
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" />
                      </div>
                      <div className='w-100'>
                        <Accordion.Header className='faq-header'> How much can I ofin? </Accordion.Header>
                        <Accordion.Body className='collapse show '>
                          <div className='faq-body pr-lg-130'>
                            <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className='single-widget-one wow fadeInUp px-sm-5 px-4'>
                      <div className="widget-icon ">
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" />
                      </div>
                      <div className='w-100'>
                        <Accordion.Header className='faq-header'> Do you offering refinancing ? </Accordion.Header>
                        <Accordion.Body className='collapse show '>
                          <div className='faq-body pr-lg-130'>
                            <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className='single-widget-one wow fadeInUp px-sm-5 px-4'>
                      <div className="widget-icon ">
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" />
                      </div>
                      <div className='w-100'>
                        <Accordion.Header className='faq-header'> Can I do all of my banking with you? </Accordion.Header>
                        <Accordion.Body className='collapse show '>
                          <div className='faq-body pr-lg-130'>
                            <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className='single-widget-one wow fadeInUp px-sm-5 px-4'>
                      <div className="widget-icon ">
                        <FontAwesomeIcon icon="fa-regular fa-circle-question" />
                      </div>
                      <div className='w-100'>
                        <Accordion.Header className='faq-header'> When should i apply? </Accordion.Header>
                        <Accordion.Body className='collapse show '>
                          <div className='faq-body pr-lg-130'>
                            <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      {/* Footer Section */}
      <footer className="App-footer">
        <Footer />
      </footer>
    </>
  );
}
export default Products