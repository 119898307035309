import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const AbContent = () => {
  const [data, setData] = useState([]);
  const [mission, setMission] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}aboutTitleContent`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        } })
  }, []);
  useEffect(() => {
    axios.get(`${BASE_URL}mission-vision`)
      .then((response) => {
        if (response?.data?.result === true) {
          setMission(response.data.data);
        } })
  }, []);
  return (
    <>
      <section className='safe-deposit-area overflow-hidden bg_white pt-125 pb-115'>
        <Container>
          <Row className='align-items-center gy-4 gy-lg-0'>
            <Col lg={6} md={9} className='mx-auto'>
              <div className='card-img'>
                <img className='bg-img' alt='bg-img' src={imgpath.about_bg} />
                <img className='img-fluid wow fadeInUp ' alt='credit-card' src={`${BASE_URL}aboutTitleContent/uploads/${data.mainImage}`} />
              </div>
            </Col>
            <Col lg={6}>
              <div className='section-title text-start'>
                <h2 className='wow fadeInUp'> {data.mainTitle} </h2>
                <p className='pl-10 border-left wow fadeInUp'> {data.main_desc} </p>
              </div>
              <Row>
                <Col md={6}>
                  <div className='payment-system mt-80 wow fadeInRight'>
                    <img src={`${BASE_URL}aboutTitleContent/uploads/${data.firstIcon}`} width="50" alt='icon' />
                    <h5 className='mt-20 mb-10'> {data.firstTitle} </h5>
                    <p> {data.firstDesc} </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='payment-system mt-65 wow fadeInRight'>
                    <img src={`${BASE_URL}aboutTitleContent/uploads/${data.secondIcon}`} alt='icon' width="50" />
                    <h5 className='mt-20 mb-10'> {data.secondTitle} </h5>
                    <p> {data.secondDesc} </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Mission and Vision - App Show Case Area  */}
      <section className='app-showcase-area'>
        <img className='shape_img' alt='bg' src={imgpath.about_bg2} />
        <Container>
          <div className='saas-section-title text-center mb-60 wow fadeInUp'>
            <h2> {mission.mainTitle} </h2>
          </div>
          <Row>
            <Col lg={6}>
              <div className='app-showcase-item'>
                <div className='round wow floatingBubbles' >  </div>
                <img src={`${BASE_URL}mission-vision/uploads/${mission.visionIcon}`} alt='vision' className='pb-3 icon' />
                <h4 className='wow fadeInUp'> {mission.visionTitle} </h4>
                <p className='wow fadeInUp'> {mission.visionDesc} </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className='app-showcase-item box-two'>
                <div className='round wow floatingBubbles' >  </div>
                <img src={`${BASE_URL}mission-vision/uploads/${mission.missionIcon}`} alt='vision' className='pb-3 icon' />
                <h4 className='wow fadeInUp'> {mission.missionTitle} </h4>
                <p className='wow fadeInUp'> {mission.missionDesc} </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default AbContent