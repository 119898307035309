import React, { useState, useEffect, useMemo } from 'react';
import InvestorHeader from './InvestorHeader';
import { CopyRights } from '../pages/Footer';
import Loader from '../components/Loader';
import Modal from 'react-bootstrap/Modal';
import { Form, FormControl, FormGroup, } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet-async';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const InvReqq = () => {
    const [data, setData] = useState([])
    const [loding, setLoding] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [helmetData, setHelmetData] = useState([]);
    const slug = 'investor-requests';
    useEffect(() => {
        axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setHelmetData(response.data.data);
                }
            })
    }, []);
    const currentUserID = localStorage.getItem("userid");
    const getData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}requests?status=pending&status=disapprove`);
            const modifiedData = response.data.data.map(item => {
                // Check if the current user disapproved or is the approving user
                if (item.disapprovingUsers.includes(currentUserID) || item.invID === currentUserID) {
                    return { ...item, status: "disapprove" };
                } else {
                    // If not, show it as pending
                    return { ...item, status: "pending" };
                }
            });
            setData(modifiedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        getData()
    }, [])
    const saveData = async (id, formData,) => {
        const status = formData.status;
        const invID = localStorage.getItem("userid")
        let payload = {
            ...formData,
            invID: invID,
        }
        setLoding(true);
        try {
            const response = await axios.put(`${BASE_URL}requests/${id}`, payload, status);
            if (response.data.result) {
                toast.success(response.data.message, { theme: "colored" });
                getData();
            } else {
                toast.error(response.data.message, { theme: "colored" });
            }
        } catch (error) {
            console.error("Error updating status:", error);
        } finally {
            setLoding(false);
        }
    };
    const handleBorrowerClick = (id) => {
        console.log("Clicked on investor with ID:", id);
        setModalShow({ show: true, userID: id });
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(Math.ceil(data.length / perPage));
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [sortedColumn, setSortedColumn] = useState(null);
    const sortedData = useMemo(() => {
        const sortableData = [...data];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'descending' ? -1 : 1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);
    const filteredData = useMemo(() => {
        return sortedData.filter(item => {
            const values = Object.values(item).map(val => (val ? val.toString().toLowerCase() : ''));
            return values.some(val => val.includes(searchQuery.toLowerCase()));
        });
    }, [sortedData, searchQuery]);
    const displayData = useMemo(() => {
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        return filteredData.slice(startIndex, endIndex);
    }, [currentPage, perPage, filteredData]);
    useEffect(() => {
        setTotalPages(Math.ceil(filteredData.length / perPage));
    }, [filteredData, perPage]);
    const Size = [10, 25, 50, 100];
    const handlePerPageChange = (event) => {
        const newPerPage = parseInt(event.target.value, 10);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        setSortConfig({ key, direction });
    };
    const upArrowColor = sortConfig.direction === 'ascending' ? 'sorting_asc' : 'sorting';
    const downArrowColor = sortConfig.direction === 'descending' ? 'sorting_desc' : 'sorting';
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const generatePageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : 'disabled'}`}>
                    <button className="page-link" onClick={() => handlePageClick(i)}> {i} </button>
                </li>
            );
        }
        return pageItems;
    };
    const generateTableHead = () => {
        const columns = [
            { key: 'index', label: 'S.No' },
            { key: 'forcompanyName', label: 'CompanyName' },
            { key: 'companyType', label: 'CompanyType' },
            { key: 'teamSize', label: 'TeamSize' },
            { key: 'amountRequired', label: 'Amount Required  (₹)' },
            { key: 'productName', label: 'Product Name' },
            { key: 'status', label: 'Status' },
        ];
        return (
            <thead>
                <tr>
                    {columns.map((column) => (
                        <th key={column.key} className={`${upArrowColor} ${downArrowColor}`} onClick={() => handleSort(column.key)}>
                            {column.label}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    };
    useEffect(() => {
        setSortedColumn(sortConfig.key);
    }, [sortConfig.key]);
    return (
        <>
            <Helmet>
                {helmetData.length > 0 && <title>{String(helmetData[0].metaTitle)}</title>}
                {helmetData.length > 0 && <meta name="description" content={String(helmetData[0].meta_desc)} />}
                {helmetData.length > 0 && <meta name="keywords" content={String(helmetData[0].keywords)} />}
            </Helmet>
            <header>
                <InvestorHeader />
            </header>
            <main>
                <section className='loan-deatils-area bg_disable pt-100 pb-100'>
                    <Container>
                        {loding && <Loader />}
                        <Row className=' wow fadeInUp'>
                            <Col lg={12}>
                                <div className='info-box'>
                                    <div id='table_wrapper' className='dataTables_wrapper dt-bootstrap5 no-footer'>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} >
                                                <div className='dataTables_length' id='table_length'>
                                                    <label className='d-flex'> Show <select name="table_length" aria-controls="table" className="form-select form-select-sm w-auto ms-2 me-2"
                                                        onChange={handlePerPageChange} value={perPage} >
                                                        {Size.map((option) => (<option key={option} value={option}> {option}  </option>))}
                                                    </select> entries</label>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} >
                                                <div id="table_filter" className="dataTables_filter ">
                                                    <label className='d-flex justify-content-end' > Search: <input type="text" className="form-control w-auto ms-2 form-control-sm" aria-controls="table" value={searchQuery} onChange={handleSearchChange} /> </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <table id="table" className='table table-responsive table-bordered example dt-responsive table-borderless table-thead-bordered nowrap table-align-middle card-table dataTable no-footer dtr-inline'>
                                                    {generateTableHead()}
                                                    {
                                                        displayData.map((dataInv, index) => {
                                                            return (
                                                                <tbody key={dataInv._id} >
                                                                    <tr className='odd'>
                                                                        <td> {((currentPage - 1) * perPage) + index + 1} </td>
                                                                        <td onClick={() => handleBorrowerClick(dataInv.userID)} className='company-name' > {dataInv.forcompanyName} </td>
                                                                        <td> {dataInv.companyType} </td>
                                                                        <td> {dataInv.teamSize} </td>
                                                                        <td> {dataInv.amountRequired} </td>
                                                                        <td> {dataInv.productName} </td>
                                                                        <td >
                                                                            <Form className="d-flex gap-3" onChange={(e) => saveData(dataInv._id, { status: e.target.value })}>
                                                                                <FormGroup className=" ">
                                                                                    <FormControl as="select" style={{ fontSize: "13px" }} defaultValue={dataInv.status} className={dataInv.status === 'pending' ? 'pending-row' : 'disapprove-row'}>
                                                                                        <option value="pending" > Pending </option>
                                                                                        <option value="active" > Approve </option>
                                                                                        <option value="disapprove" > Disapprove </option>
                                                                                    </FormControl>
                                                                                </FormGroup>
                                                                            </Form>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        })}
                                                    {displayData && !displayData.length &&
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="10" className="text-center">
                                                                    <div className="p-2">No matching records found</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }
                                                </table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={5}>
                                                <div className="dataTables_info" id="table_info" aria-live="polite"> Showing {((currentPage - 1) * perPage) + 1} to {Math.min(currentPage * perPage, filteredData.length)} of {filteredData.length} entries </div>
                                            </Col>
                                            <Col sm={12} md={7}>
                                                <div className="dataTables_paginate paging_simple_numbers " id="table_paginate">
                                                    <ul className="pagination d-flex justify-content-end">
                                                        <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`} id="table_previous" >
                                                            <button className="page-link" onClick={handlePreviousPage} disabled={currentPage === 1}> Previous </button>
                                                        </li>
                                                        {generatePageItems()}
                                                        <li className={`paginate_button page-item next ${currentPage === totalPages ? 'disabled' : ''}`} id="table_next">
                                                            <button className="page-link" onClick={handleNextPage} disabled={currentPage === totalPages}> Next </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <MyVerticallyCenteredModal
                    show={modalShow.show}
                    onHide={() => setModalShow(false)}
                    userID={modalShow.userID}
                />
            </main>
            <footer className=' footer footer-3 fixed-footer'>
                <CopyRights />
            </footer>
        </>
    )
}
export default InvReqq
function MyVerticallyCenteredModal(props) {
    const [investorData, setInvestorData] = useState([]);
    const getData = async () => {
        const id = props.userID;
        console.log("Fetching data for investor ID:", id);
        await axios.get(`${BASE_URL}borrower/${id}`)
            .then((res) => {
                console.log("Fetched data:", res.data);
                setInvestorData(res.data.data);
            })
            .catch((error) => {
                console.error("Error fetching investor data:", error);
            });
    };
    useEffect(() => {
        if (props.show && props.userID) {
            getData();
        }
    }, [props.show, props.userID]);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='ps-2'>{investorData.companyName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={6} >
                        <p className='p-2'> Email : {investorData.email}</p>
                        <p className='p-2'> Pan No: {investorData.pan}</p>
                        <p className='p-2'> GSTIN No: {investorData.gstin}</p>
                    </Col>
                    <Col sm={6}>
                        <p className='p-2'> Mobile No: {investorData.phoneNo}</p>
                        <p className='p-2'> Aadhaar No: {investorData.aadhaar}</p>
                        <p className='p-2'> Addess: {investorData.address}</p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};