import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CopyRights } from "../pages/Footer";
import BorrowerHeader from "./BorrowerHeader";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const Paydue = () => {
    const { id } = useParams();
    const { register, handleSubmit, formState: { errors }, control, reset } = useForm({ mode: "all" });
    const [err, setErr] = useState();
    const navigate = useNavigate()
    const currentDate = new Date();
    const saveRequestData = async (data) => {
        try {
            const userId = localStorage.getItem("userid");
            const payload = {
                ...data,
                userId: userId,
                id: id,
            };
            const response = await axios.post(`${BASE_URL}payment/add-payment`, payload);
            if (response.data.result) {
                console.log("Payment submitted successfully");
                reset();
                navigate(`/borrower-payments/${id}`);
            } else {
                console.error("Payment submission failed:", response.data.message);
            }
        }
        catch (error) {
            console.error("Error submitting payment:", error);
        }
    };
    return (
        <>
            <header> <BorrowerHeader /> </header>
            <main>
                <section className="pt-120 pb-5 bg_disable">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={7} sm={12}>
                                <div className="loan-details-widget add-request-form">
                                    <Form onSubmit={handleSubmit(saveRequestData)}>
                                        <Row className="gy-4 ">
                                            <Col md={6} sm={12}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="paidDate" className="label"> Date </Form.Label>
                                                    <Controller id="paidDate" control={control} name="paidDate" defaultValue={currentDate}
                                                        render={({ field }) => (<DatePicker {...field} className='form-control date-picker' selected={new Date(field.value)} dateFormat="MM/dd/yyyy" readOnly />)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group >
                                                    <Form.Label className="label" htmlFor="paidAmount"> Amount Required* </Form.Label>
                                                    <Form.Control
                                                        type="number" id="paidAmount" className="form-control"
                                                        {...register("paidAmount", {
                                                            required: "Amount Range is required",
                                                        })}
                                                        name="paidAmount" isInvalid={errors?.paidAmount} />
                                                    {errors?.paidAmount && (<span className="text-danger">{errors?.paidAmount.message}</span>)}
                                                    {err?.paidAmount && (<span className="text-danger">{err?.paidAmount}</span>)}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="paymentMode" className="label"> Payment Mode* </Form.Label>
                                                    <Controller
                                                        name="paymentMode"
                                                        control={control}
                                                        id="paymentMode"
                                                        rules={{ required: "Payment Mode is required" }}
                                                        className="w-100 "
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Form.Select {...field}>
                                                                <option className="options current" value="" defaultChecked> Select </option>
                                                                <option value="NEFT" className="options"> NEFT </option>
                                                                <option value="INPS" className="options"> INPS </option>
                                                                <option value="Cheque" className="options"> Cheque </option>
                                                                <option value="RTGS" className="options"> RTGS </option>
                                                                <option value="UPI" className="options"> UPI </option>
                                                                <option value="Cash" className="options"> Cash </option>
                                                            </Form.Select>
                                                        )}
                                                    />
                                                    {errors.paymentMode && (<span className="text-danger"> {errors?.paymentMode && errors.paymentMode.message} </span>)}
                                                    {err?.paymentMode && (<span className="text-danger">{err?.paymentMode}</span>)}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group>
                                                    <Form.Label className="label" htmlFor="referenceNumber"> Ref. No* </Form.Label>
                                                    <Form.Control
                                                        type="text" id="referenceNumber" className="form-control"
                                                        {...register("referenceNumber", {
                                                            required: "Reference Number is required",
                                                        })}
                                                        name="referenceNumber" isInvalid={errors?.referenceNumber} />
                                                    {errors?.referenceNumber && (<span className="text-danger">{errors?.referenceNumber.message}</span>)}
                                                    {err?.referenceNumber && (<span className="text-danger">{err?.referenceNumber}</span>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="login-form-2-row mt-40">
                                            <Col md={12} className="text-center mt-3">
                                                <button type="submit" className="theme-btn">
                                                    Submit
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <footer className=" footer footer-3 fixed-footer ">
                <CopyRights />
            </footer>
        </>
    )
}
export default Paydue