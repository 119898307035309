import React, { useState, useEffect } from 'react'
import Header from './Header';
import Footer from './Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import AbContent from '../aboutPage/AbContent';
import Teams from '../aboutPage/Teams';
import InvSwiper from '../aboutPage/InvSwiper';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const About = () => {
  const [data, setData] = useState([]);
  const slug = 'about';
  useEffect(() => {
    axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      })
  }, []);
  return (
    <>
      <Helmet>
        {data.length > 0 && <title>{String(data[0].metaTitle)}</title>}
        {data.length > 0 && <meta name="description" content={String(data[0].meta_desc)} />}
        {data.length > 0 && <meta name="keywords" content={String(data[0].keywords)} />}
      </Helmet>
      {/* Navigation */}
      <header className="App-header">
        <Header />
      </header>
      <main>
        {/* Breadcrumb area */}
        <section className="breadcrumb-area">
          <div className="breadcrumb-widget pt-145 pb-125">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="breadcrumb-content">
                    <h1> About </h1>
                    <ul>
                      <li> <a href="/">home</a> </li>
                      <li> About </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* safe deposit area  &  Mission and Vision - App Show Case Area  */}
        <AbContent />
        {/* Team Start */}
        <Teams />
        {/* Our Investors */}
        <InvSwiper />
      </main>
      {/* Footer Section */}
      <footer className="App-footer">
        <Footer />
      </footer>
    </>
  );
}
export default About