import { useForm } from "react-hook-form";
import { Form, Col, Row } from "react-bootstrap";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
function BorrowerSignUp() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, watch, } = useForm({ mode: "all" });
  const password = watch("password", "");
  const [err, setErr] = useState();
  const saveBorrowData = (data) => {
    axios.post(BASE_URL + "borrower/signup", data).then((res) => {
      if (res?.data?.result === true) {
        navigate("/login");
        console.log(res.data.message);
      } else if (res?.data?.result === false) {
        setErr(res.data.errors);
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit(saveBorrowData)}>
      <Row className="gy-3 mt-3">
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="cName" className="label"> Company Name* </Form.Label>
            <Form.Control type="text" id="cName" className="form-control"
              {...register("companyName", { required: "Company name is required", })}
              name="companyName" isInvalid={errors?.companyName} ></Form.Control>
            {errors?.companyName && ( <span className="text-danger"> {errors?.companyName && errors.companyName.message} </span> )}
            {err?.companyName && ( <span className="text-danger">{err?.companyName}</span> )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="brwEmail" className="label"> Email* </Form.Label>
            <Form.Control type="email" id="brwEmail" className="form-control"
              {...register("email", { required: "Email is required",
                pattern: { value: /^\S+@\S+$/, message: "Invalid email address", },
                validate: (value) => value === value.toLowerCase() || "Email must contain only lowercase letters", })}
              isInvalid={errors?.email} ></Form.Control>
            {errors?.email && ( <span className="text-danger"> {errors?.email && errors.email.message} </span> )}
            {err?.email && <span className="text-danger">{err?.email}</span>}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label className="label" htmlFor="brwNumber"> Phone No* </Form.Label>
            <Form.Control type="text" id="brwNumber" className="form-control"
              {...register("phoneNo", {
                required: "Phone number is required",
                pattern: { value: /^\d+$/, message: "Phone number must only contain numeric characters", },
                minLength: { value: 10, message: "Minimum 10 digit required", },
                maxLength: { value: 10, message: "Maximum 10 digit allowed", },
              })}
              name="phoneNo" isInvalid={errors?.phoneNo}
            />
            {errors?.phoneNo && ( <span className="text-danger">{errors?.phoneNo.message}</span> )}
            {err?.phoneNo && ( <span className="text-danger">{err?.phoneNo}</span> )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label className="label" htmlFor="brwGSTIN"> GSTIN* </Form.Label>
            <Form.Control type="text" id="brwGSTIN" className="form-control"
              {...register("gstin", { required: "GSTIN is required",
                // pattern: {
                //   value: /^\d+$/,
                //   message: "GSTIN must only contain numeric characters",
                // },
                minLength: { value: 15, message: "Minimum 15 digit required", },
                maxLength: { value: 15, message: "Maximum 15 digit allowed", }, 
              })}
              name="gstin" isInvalid={errors?.gstin}
            />
            {errors?.gstin && ( <span className="text-danger">{errors?.gstin.message}</span> )}
            {err?.gstin && <span className="text-danger">{err?.gstin}</span>}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label className="label" htmlFor="brwPAN"> PAN No* </Form.Label>
            <Form.Control
              type="text" id="brwPAN" className="form-control"
              {...register("pan", { required: "PAN is required",
                // pattern: {
                //   value: /^\d+$/,
                //   message: "PAN must only contain numeric characters",
                // },
                minLength: { value: 10, message: "Minimum 10 digit required", },
                maxLength: { value: 10, message: "Maximum 10 digit allowed", },
              })}
              name="pan" isInvalid={errors?.pan}
            />
            {errors?.pan && ( <span className="text-danger">{errors?.pan.message}</span> )}
            {err?.pan && <span className="text-danger">{err?.pan}</span>}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label className="label" htmlFor="brwAadhar">
              Aadhaar*
            </Form.Label>
            <Form.Control type="text" id="brwAadhar" className="form-control"
              {...register("aadhaar", {
                required: "Aadhaar is required",
                pattern: { value: /^\d+$/, message: "Aadhaar must only contain numeric characters", },
                minLength: { value: 12, message: "Minimum 12 digit required", },
                maxLength: { value: 12, message: "Maximum 12 digit allowed", },
              })}
              name="aadhaar" isInvalid={errors?.aadhaar}
            />
            {errors?.aadhaar && (
              <span className="text-danger">{errors?.aadhaar.message}</span>
            )}
            {err?.aadhaar && (
              <span className="text-danger">{err?.aadhaar}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={12} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="brwAddress" className="label"> Address </Form.Label>
            <Form.Control type="text" id="brwAddress" className="form-control"
              {...register("address", {
                required: "Address  is required",
              })}
              name="address"
              isInvalid={errors?.address}
            ></Form.Control>
            {errors?.address && (
              <span className="text-danger">
                {errors?.address && errors.address.message}
              </span>
            )}
            {err?.address && (
              <span className="text-danger">{err?.address}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="brwPassword" className="label"> Password* </Form.Label>
            <Form.Control type="password" id="brwPassword" className="form-control"
              {...register("password", {
                required: "Password is Required",
                minLength: {
                  value: 8,
                  message: "Password must be minimum 8 characters",
                },
                validate: (value) => {
                  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
                  return (
                    regex.test(value) ||
                    "Password must contain atleast one uppercase,symbol and number"
                  );
                },
              })}
              isInvalid={errors?.password}
            />
            {errors?.password && errors.password.message && (
              <span className="text-danger">{errors.password.message}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="brwCnfrmPassword" className="label"> Confirm Password* </Form.Label>
            <Form.Control id="brwCnfrmPassword" type="password" className="form-control"
              {...register("confirmpass", {
                required: "Confirm Password is Required",
                minLength: {
                  value: 8,
                  message: "Password must be minimum 8 characters",
                },
                validate: (value) =>
                  value === password || "Passwords do not match",
              })}
              isInvalid={errors?.confirmpass}
            />
            {errors?.confirmpass && (
              <span className="text-danger">{errors.confirmpass.message}</span>
            )}
            {err?.password && (
              <span className="text-danger">{err?.password}</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="login-form-2-row">
        <Col md={12} className="text-center">
          <button type="submit" className="theme-btn"> Submit </button>
          <p className="mb-0"> Already have an account? <Link to="/login" className="register-btn"> Log In </Link> </p>
        </Col>
      </Row>
    </Form>
  );
}
export default BorrowerSignUp;
