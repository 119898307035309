import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const Teams = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}employees`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      })
  }, []);
  return (
    <>
      <section className='leadership-area pt-135 pb-140 bg_white'>
        <Container>
          <Row className='align-items-end '>
            <Col lg={6} className='mx-auto mb-50'>
              <div className='section-title text-center wow fadeInRight'>
                <span className='short-title mt-0'> TEAM </span>
                <h2 className='mb-0'> Meet our leadership team </h2>
              </div>
            </Col>
          </Row>
          <Row className='pt-65 gy-md-0 gy-4'>
            {
              data.map((team) => {
                return (
                  <Col lg={3} md={6} key={team._id}>
                    <div className='single-team-item  wow fadeInUp'>
                      <div className='team-thumb'> <img src={`${BASE_URL}employees/uploads/${team.empImage}`} alt='team' className='img-fluid' /> </div>
                      <div className='team-content'>
                        <h2 className='title'> <a href='/'> {team.employeeName} </a> </h2>
                        <span> {team.emp_designation} </span>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Teams