import React, { useState, useEffect } from 'react';
import CountUp from "react-countup";
import axios from 'axios';
import { useInView } from "react-intersection-observer";
import { imgpath } from '../Files';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const Dashboard = () => {
    const { ref, inView } = useInView({
        triggerOnce: false,
    });
    const [totalRequests, setTotalRequests] = useState(0);
    const [completedRequests, setCompletedRequests] = useState(0);
    const [pendingRequests, setPendingRequests] = useState(0);
    const [approvedRequests, setApprovedRequests] = useState(0);
    const getData = async () => {
        const userId = localStorage.getItem("userid");
        try {
            const response = await axios.get(`${BASE_URL}requests?userId=${userId}`);
            const requestData = response.data.data;
            const paymentsResponse = await axios.get(`${BASE_URL}payment?userId=${userId}`);
            const paymentsData = paymentsResponse.data.data;
            const combinedData = requestData.map(request => {
                const matchingPayments = paymentsData.filter(payment => payment.reqId === request._id);
                const paidAmount = matchingPayments.reduce((total, payment) => total + payment.paidAmount, 0);
                return {
                    ...request,
                    paidAmount,
                };
            });
            // Set the total requests count
            setTotalRequests(requestData.length);
            // Filter completed requests
            const completedRequestsData = combinedData.filter(request => {
                return (
                    (request.amountRequired === request.paidAmount) ||
                    (request.amountRequired - request.paidAmount === 0)
                );
            });
            setCompletedRequests(completedRequestsData.length);
            // Filter pending requests
            const pendingRequestsCount = requestData.filter(request => request.status !== "active").length;
            setPendingRequests(pendingRequestsCount);
            // Filter approved requests
            const approvedRequestsCount = requestData.filter(request => request.status === "active").length;
            setApprovedRequests(approvedRequestsCount);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        getData();
    }, []);
    return (
        <section className="safe-deposit-area overflow-hidden pt-155 pb-115 bg_disable request-counter-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="counter  wow fadeInUp " >
                            <div className="counter-content">
                                <div className='counter-icon'>
                                    <img src={imgpath.total_req} alt="" className="img-fluid" />
                                </div>
                                <h3> TOTAL REQUEST</h3>
                                {/* <span className="counter-value"> 100 </span> */}
                                <span className="counter-value"> {totalRequests} </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="counter wow fadeInUp ">
                            <div className="counter-content">
                                <div className='counter-icon'>
                                    <img src={imgpath.completed_req} alt="" className="img-fluid" />
                                </div>
                                <h3> COMPLETED REQUEST </h3>
                                <span className=' counter-value '> {completedRequests} </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="counter  wow fadeInUp " >
                            <div className="counter-content">
                                <div className='counter-icon'>
                                    <img src={imgpath.pending_req} alt="" className="img-fluid" />
                                </div>
                                <h3> PENDING REQUEST </h3>
                                <span className='counter-value' > {pendingRequests} </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6" ref={ref}>
                        <div className="counter  wow fadeInUp">
                            <div className="counter-content">
                                <div className='counter-icon'>
                                    <img src={imgpath.approved_req} alt="" className="img-fluid" />
                                </div>
                                <h3> APPROVED REQUEST </h3>
                                {/* <span className='counter-value'><CountUp scrollSpyOnce start={inView ? 0 : 0} end={approvedRequests} duration={1} /></span> */}
                                <span className='counter-value'>  <CountUp scrollSpyOnce start={inView ? 0 : 10} end={inView ? approvedRequests : approvedRequests} duration={1} /> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Dashboard
