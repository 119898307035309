import React, { useState, } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form, Col, Row } from "react-bootstrap";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
function InvestorSignUp() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({ mode: "all" });
  const password = watch("password", "");
  const [err, setErr] = useState();
  const saveInvestorData = (data) => {
    axios.post(BASE_URL + "investor/signup", data).then((res) => {
      if (res?.data?.result === true) {
        navigate("/login");
        console.log(res.data.message);
      } else if (res?.data?.result === false) {
        setErr(res.data.errors);
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit(saveInvestorData)}>
      <Row className="gy-3 mt-3">
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="invCmpName" className="label">
              Company Name*
            </Form.Label>
            <Form.Control
              type="text"
              id="invCmpName"
              className="form-control"
              {...register("companyName", {
                required: "Company name is required",
              })}
              name="companyName"
              isInvalid={errors?.companyName}
            ></Form.Control>
            {errors?.companyName && (
              <span className="text-danger">
                {errors?.companyName && errors.companyName.message}
              </span>
            )}
            {err?.companyName && (
              <span className="text-danger">{err?.companyName}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="invContactPerson" className="label">
              Contact Person*
            </Form.Label>
            <Form.Control
              type="text"
              id="invContactPerson"
              className="form-control"
              {...register("contactPerson", {
                required: "Contact Person name is required",
              })}
              name="contactPerson"
              isInvalid={errors?.contactPerson}
            ></Form.Control>
            {errors?.contactPerson && (
              <span className="text-danger">
                {errors?.contactPerson && errors.contactPerson.message}
              </span>
            )}
            {err?.contactPerson && (
              <span className="text-danger">{err?.contactPerson}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="invType" className="label">
              Investor Type*
            </Form.Label>
            <Controller
              name="investorType"
              control={control}
              id="invType"
              rules={{ required: "Investor Type is required" }}
              className="w-100  nice-select"
              defaultValue=""
              render={({ field }) => (
                <Form.Select {...field}>
                  <option className="options current" value="" defaultChecked>
                    Select
                  </option>
                  <option value="Individual" className="options">
                    Individual
                  </option>
                  <option value="Bank" className="options">
                    Bank
                  </option>
                  <option value="Mutual Fund" className="options">
                    Mutual Fund
                  </option>
                  <option value="Family Office" className="options">
                    Family Office
                  </option>
                  <option value="NBFC" className="options">
                    NBFC
                  </option>
                </Form.Select>
              )}
            />
            {errors.investorType && (
              <span className="text-danger">
                {errors?.investorType && errors.investorType.message}
              </span>
            )}
            {err?.investorType && (
              <span className="text-danger">{err?.investorType}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="invEmail" className="label">
              Email*
            </Form.Label>
            <Form.Control
              type="email"
              id="invEmail"
              className="form-control"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/,
                  message: "Invalid email address",
                },
                validate: (value) =>
                  value === value.toLowerCase() ||
                  "Email must contain only lowercase letters",
              })}
              isInvalid={errors?.email}
            ></Form.Control>
            {errors?.email && (
              <span className="text-danger">
                {errors?.email && errors.email.message}
              </span>
            )}
            {err?.email && <span className="text-danger">{err?.email}</span>}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label className="label" htmlFor="invMobNo">
              Phone No*
            </Form.Label>
            <Form.Control
              type="text"
              id="invMobNo"
              className="form-control"
              {...register("phoneNo", {
                required: "Phone number is required",
                pattern: {
                  value: /^\d+$/,
                  message: "Phone number must only contain numeric characters",
                },
                minLength: {
                  value: 10,
                  message: "Minimum 10 digit required",
                },
                maxLength: {
                  value: 10,
                  message: "Maximum 10 digit allowed",
                },
              })}
              name="phoneNo"
              isInvalid={errors?.phoneNo}
            />
            {errors?.phoneNo && (
              <span className="text-danger">{errors?.phoneNo.message}</span>
            )}
            {err?.phoneNo && (
              <span className="text-danger">{err?.phoneNo}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label className="label" htmlFor="invGSTIN">
              {" "}
              GSTIN*
            </Form.Label>
            <Form.Control
              type="text"
              id="invGSTIN"
              className="form-control"
              {...register("gstin", {
                required: "GSTIN is required",
                // pattern: {
                //   value: /^\d+$/,
                //   message: "GSTIN must only contain numeric characters",
                // },
                minLength: {
                  value: 15,
                  message: "Minimum 15 digit required",
                },
                maxLength: {
                  value: 15,
                  message: "Maximum 15 digit allowed",
                },
              })}
              name="gstin"
              isInvalid={errors?.gstin}
            />
            {errors?.gstin && (
              <span className="text-danger">{errors?.gstin.message}</span>
            )}
            {err?.gstin && <span className="text-danger">{err?.gstin}</span>}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label className="label" htmlFor="invPAN">
              PAN No*
            </Form.Label>
            <Form.Control
              type="text"
              id="invPAN"
              className="form-control"
              {...register("pan", {
                required: "PAN is required",
                // pattern: {
                //   value: /^\d+$/,
                //   message: "PAN must only contain numeric characters",
                // },
                minLength: {
                  value: 10,
                  message: "Minimum 10 digit required",
                },
                maxLength: {
                  value: 10,
                  message: "Maximum 10 digit allowed",
                },
              })}
              name="pan"
              isInvalid={errors?.pan}
            />
            {errors?.pan && (
              <span className="text-danger">{errors?.pan.message}</span>
            )}
            {err?.pan && <span className="text-danger">{err?.pan}</span>}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="invAddress" className="label">
              Address
            </Form.Label>
            <Form.Control
              type="text"
              id="invAddress"
              className="form-control"
              {...register("address", {
                required: "Address  is required",
              })}
              name="address"
              isInvalid={errors?.address}
            ></Form.Control>
            {errors?.address && (
              <span className="text-danger">
                {errors?.address && errors.address.message}
              </span>
            )}
            {err?.address && (
              <span className="text-danger">{err?.address}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="invPassword" className="label">
              Password*
            </Form.Label>
            <Form.Control
              type="password"
              id="invPassword"
              className="form-control"
              {...register("password", {
                required: "Password is Required",
                minLength: {
                  value: 8,
                  message: "Password must be minimum 8 characters",
                },
                validate: (value) => {
                  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
                  return (
                    regex.test(value) ||
                    "Password must contain atleast one uppercase,symbol and number"
                  );
                },
              })}
              isInvalid={errors?.password}
            />
            {errors?.password && errors.password.message && (
              <span className="text-danger">{errors.password.message}</span>
            )}
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group>
            <Form.Label htmlFor="invCnfrmPassword" className="label">
              Confirm Password*
            </Form.Label>
            <Form.Control
              type="password"
              className="form-control"
              {...register("confirmpass", {
                required: "Confirm Password is Required",
                minLength: { value: 8, message: "Password must be minimum 8 characters", },
                validate: (value) => value === password || "Passwords do not match",
              })}
              isInvalid={errors?.confirmpass} />
            {errors?.confirmpass && (<span className="text-danger">{errors.confirmpass.message}</span>)}
            {err?.password && (<span className="text-danger">{err?.password}</span>)}
          </Form.Group>
        </Col>
      </Row>
      <Row className="login-form-2-row">
        <Col md={12} className="text-center">
          <button type="submit" className="theme-btn"> Submit </button>
          <p className="mb-0"> Already have an account? <Link to="/login" className="register-btn"> Log In </Link>
          </p>
        </Col>
      </Row>
    </Form>
  );
}

export default InvestorSignUp;
