import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { imgpath } from '../Files';
const Banner = () => {
  return (
    <div className='banner-area-2 pt-100 pb-95' id="banner_animation">
      <Container>
        <Row className='align-items-center'>
          <Col md={6} sm={12}  >
            <div className='banner-content'>
              <img className='shape' alt='shapeImg' src={imgpath.shape12} />
              <h1 className='wow fadeInUp mb-0'>Smart finance solutions for your business </h1>
              <p className='wow fadeInUp mt-50'>Inventore veritatis et architecto beatae vitae dicta explicabo nemo enim ipsam voluptatem quia voluptas aspernatur magni.</p>
              <div className='d-flex flex-wrap mt-30'>
                <Link to='/contact' className='theme-btn me-4'> Get Started Now</Link>
              </div>
            </div>
          </Col>
          <Col md={6} lg={{ span: 5, offset: 1 }} className='pt-40'>
            <div className='banner-img'>
              <img className='main-img img-fluid wow fadeInRight' alt='banner-img' src={imgpath.bannerImg} />
              <div className="shapes">
                <img className="shape-1" src={imgpath.shape8} alt="shape" />
                <img className="shape-2" src={imgpath.shape7} alt="shape" />
                <img className="shape-3" src={imgpath.shape6} alt="shape" />
                <img className="shape-4" src={imgpath.shape10} alt="shape" />
                <img className="shape-5" src={imgpath.shape9} alt="shape" />
                <img className="shape-6" src={imgpath.shape11} alt="shape" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Banner