import React, { useState, useEffect } from 'react';
import BorrowerHeader from './BorrowerHeader';
import { CopyRights } from '../pages/Footer';
import { useForm, Controller } from "react-hook-form";
import { Form, Col, Row } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const AddRequest = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, control, } = useForm({ mode: "all" });
    const [err, setErr] = useState();
    const [data, setData] = useState([]);
    const slug = 'borrower-add-request';
    useEffect(() => {
        axios.get(`${BASE_URL}seoforHome?slug=${slug}`)
            .then((response) => {
                if (response?.data?.result === true) {
                    setData(response.data.data);
                }
            })
    }, []);
    const saveRequestData = (data) => {
        const userId = localStorage.getItem("userid")
        let payload = {
            ...data,
            userId: userId
        }
        axios.post(BASE_URL + "requests/borrower-add-request", payload)
            .then((res) => {
                if (res?.data?.result === true) {
                    navigate("/borrower-request-status");
                } else if (res?.data?.result === false) {
                    setErr(res.data.errors);
                }
            })
    };
    return (
        <>
            <Helmet>
                {data.length > 0 && <title>{String(data[0].metaTitle)}</title>}
                {data.length > 0 && <meta name="description" content={String(data[0].meta_desc)} />}
                {data.length > 0 && <meta name="keywords" content={String(data[0].keywords)} />}
            </Helmet>
            <header> <BorrowerHeader /> </header>
            <main>
                <section className="loan-deatils-area bg_disable pt-100 pb-150">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="loan-details-widget add-request-form">
                                    <Form onSubmit={handleSubmit(saveRequestData)}>
                                        <Row className="gy-4 ">
                                            <Col md={6} sm={12}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="reqcompanyName" className="label"> Company Name* </Form.Label>
                                                    <Form.Control type="text" id="reqcompanyName" className="form-control"
                                                        {...register("forcompanyName", { required: "Company name is required", })}
                                                        name="forcompanyName" isInvalid={errors?.forcompanyName} ></Form.Control>
                                                    {errors?.forcompanyName && (<span className="text-danger"> {errors?.forcompanyName && errors.forcompanyName.message} </span>)}
                                                    {err?.forcompanyName && (<span className="text-danger">{err?.forcompanyName}</span>)}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="reqcompanyType" className="label"> Company Type* </Form.Label>
                                                    <Form.Control type="text" id="reqcompanyType" className="form-control"
                                                        {...register("companyType", { required: "Company Type is required", })}
                                                        name="companyType" isInvalid={errors?.companyType} ></Form.Control>
                                                    {errors?.companyType && (<span className="text-danger"> {errors?.companyType && errors.companyType.message} </span>)}
                                                    {err?.companyType && (<span className="text-danger">{err?.companyType}</span>)}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <Form.Group className='mt-2'>
                                                    <Form.Label htmlFor="reqteamSize" className="label"> Team Size* </Form.Label>
                                                    <Form.Control type="number" id="reqteamSize" className="form-control"
                                                        {...register("teamSize", { required: "Team Size is required", })}
                                                        isInvalid={errors?.teamSize} ></Form.Control>
                                                    {errors?.teamSize && (<span className="text-danger"> {errors?.teamSize && errors.teamSize.message} </span>)}
                                                    {err?.teamSize && <span className="text-danger">{err?.teamSize}</span>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group className='mt-2'>
                                                    <Form.Label className="label" htmlFor="reqamountRequired"> Amount Required* </Form.Label>
                                                    <Form.Control type="number" id="reqamountRequired" className="form-control"
                                                        {...register("amountRequired", { required: "Amount Range is required", })}
                                                        name="amountRequired" isInvalid={errors?.amountRequired} />
                                                    {errors?.amountRequired && (<span className="text-danger">{errors?.amountRequired.message}</span>)}
                                                    {err?.amountRequired && (<span className="text-danger">{err?.amountRequired}</span>)}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group className='mt-2'>
                                                    <Form.Label htmlFor="productName" className="label"> Product Name* </Form.Label>
                                                    <Controller name="productName" control={control} id="productName"
                                                        rules={{ required: "Product Name is required" }} className="w-100 " defaultValue=""
                                                        render={({ field }) => (
                                                            <Form.Select {...field}>
                                                                <option className="options current" value="" defaultChecked> Select </option>
                                                                <option value="ProSecure" className="options"> ProSecure </option>
                                                                <option value="EliteFund" className="options"> EliteFund </option>
                                                                <option value="TrustCapital" className="options"> TrustCapital </option>
                                                                <option value="OptiFunds" className="options"> OptiFunds </option>
                                                                <option value="ExcelFunds" className="options"> ExcelFunds </option>
                                                                <option value="UnityFunds" className="options"> UnityFunds </option>
                                                            </Form.Select>)} />
                                                    {errors.productName && (<span className="text-danger"> {errors?.productName && errors.productName.message} </span>)}
                                                    {err?.productName && (<span className="text-danger">{err?.productName}</span>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="login-form-2-row mt-40">
                                            <Col md={12} className="text-center mt-3">
                                                <button type="submit" className="theme-btn"> Submit</button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className=' footer footer-3 fixed-footer '> <CopyRights /> </footer>
        </>
    )
}

export default AddRequest